@import 'sass/exports/abstracts';

.tag {
  font-size: $text-10;
  font-weight: 700;
  padding: 4px 9px;
  color: $gray-text;
  display: inline-block;
  border-radius: 2px;
  margin-right: 3px;
  @include media-breakpoint-down(lg) {
    font-size: $text-10;
  }
  &__color {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-text;
    i {
      margin-top: 2px;
    }
  }
}
