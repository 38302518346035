@import 'sass/exports/abstracts';

.single-tag {
  &__actions {
    button {
      height: auto;
      padding: 5px 10px;
      margin-right: 10px;
      transition: all 0.2s ease;
      border-radius: 2px;

      span {
        display: block;
      }
    }
    button[data-primary] {
      width: 83px;
      height: 30px;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;

      &:hover {
        background-color: $white;
        border: 1px solid $primary;
        color: $primary;
      }
    }

    button[data-secondary] {
      width: 83px;
      height: 30px;
      background-color: $white;
      border: 1px solid $primary;
      color: $primary;

      &:hover {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
      }
    }
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;

  &__checkmark {
    margin-top: 2px;
  }

  &__label {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-text;
    margin: 0;
  }
  &__input {
    display: none;
  }
}

.custom {
  &__row {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 7px -5px;
  }
  &__col {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    padding: 5px;
  }
}

.btn__wrap-delete {
  margin-top: 20px;
  .delete-tag {
    width: 100%;
    border: none;
    height: 30px;
    background-color: $gray-base;
    color: $gray-dark;
    font-weight: 500;
    font-family: $font-family;
    font-size: $text-14;
    border-radius: 2px;

    &:hover {
      background-color: $baby-blue;
    }
  }
}
