body {
  height: 100%;
  @extend %font-fix;
  font-family: $font-family;
  font-weight: 400;
  line-height: $line-height;
  color: $gray-text;
  background-color: $gray-base;
  overflow-x: hidden;
}

html {
  height: 100%;
  font-size: 14px;
  &.font-large {
    font-size: 16px;
  }
  &.font-small {
    font-size: 12px;
  }
}
