@import '/src/sass/exports/abstracts';

.file-chooser {
  z-index: 100;

  ul {
    li {
      margin: 10px 0px;

      &:last-child {
        margin-bottom: 10px;
      }

      span {
        transition: all 0.2s ease;
      }
    }
  }

  &--no-flex {
    .dropdown__toggler {
      display: block;
    }
  }

  &__item-title {
    font-size: $text-14;
  }

  .popup__body {
    margin-bottom: 10px;

    &:nth-child(3) {
      margin-bottom: 0px;
      border-bottom: 0px;

      input {
        margin-top: 5px;
      }
    }
  }

  .btn {
    padding: 12px 15px;
    border: transparent;
    background-color: #eff2f4;

    &:hover {
      background-color: darken(#eff2f4, 5%);
    }
  }
}
