@import 'sass/exports/abstracts';

.dashboard-table {
  .dashboard-table__user-check {
    background: rgba(239, 242, 244, 0.6);
  }
  .dashboard-table__check-wrap {
    background: rgba(225, 232, 237, 1);
  }

  &__checkbox {
    p {
      padding-left: 7px;
    }
  }

  &__tooltip {
    position: relative;

    input[type='checkbox']:checked + label:before {
      font-size: unset;
      line-height: unset;
    }
    &-checkbox {
      padding-left: 5px;
      top: 21px;
      right: 1px;
      position: absolute;
      width: 110px;
      background-color: $white;
      border: solid 1px $gray-dark;
      border-radius: 2px;
      color: $gray-dark;
      font-size: $text-10;
      padding: 6px 7px;
      font-weight: 400;
      text-transform: none;
      white-space: normal;
    }
  }

  &__checkbox-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    label {
      display: flex;
      align-items: baseline;
      input[type='checkbox'] {
        width: 18px;
        height: 18px;
        margin-top: 2px;
      }
      p {
        position: relative;
        top: -4px;
        left: 6px;
      }
    }

    p {
      margin-top: 3px;
      margin-bottom: 0;
      @include media-breakpoint-down(lg) {
        font-family: $font-family;
        color: $gray-medium;
        font-size: $text-12;
        font-weight: 500;
      }
    }
  }

  th,
  td {
    padding: 0 16px 0 9px;
    white-space: nowrap;
    &.table__check {
      background-color: rgba(239, 242, 244, 0.6);
      width: 34px;
      padding: 0;
      text-align: center;
    }

    &.dashboard-table {
      &__email {
        width: 30rem;
      }

      &__project-header--user {
        width: 342.864px;
      }

      &__user-unit-header {
        width: 30rem;
      }

      &__user-position-header {
        width: 30rem;
      }

      &__tag-column {
        display: flex;
        align-items: center;
      }

      &__date-header {
        width: 11.571rem;
      }

      &__username-header {
        width: 24rem;
        padding-left: 0;
        i {
          margin-left: 8px;
        }
      }

      &__activity {
        padding-left: 0;
        padding-right: 15px;
      }

      &__user-status-header {
        padding-left: 0;
        width: 6.7rem;

        i {
          margin-left: 8px;
        }
      }

      &__sort-header {
        padding: 0 4px 0 12px;
        width: 0;
        @include media-breakpoint-up(xxl) {
          padding-right: 0;
        }
      }

      &__user-check {
        padding: 0 0 0 4px;
      }

      &__name-tag {
        padding: 0;
        display: flex;
        align-items: center;
        span {
          font-size: $text-7;
          margin-left: 7px;
          padding: 2px 3px;
          text-transform: uppercase;
          color: $primary;
          width: 30px;
          border: 1px solid $primary;
          border-radius: 2px;
        }
      }

      &__dropdown-header {
        padding-right: 9px;
        .header__action {
          width: 0;
          min-width: auto;
          padding: 0 5px 0 6px;
          color: $white;
          &:hover {
            color: $gray-medium;
          }
        }
        .user-bulk-dropdown {
          z-index: 1;
        }
      }

      &__project-header {
        padding-left: 15px;
      }

      &__project-column {
        padding-left: 15px;
      }

      &__star {
        padding-right: 0px;
      }
      &--inactive {
        color: red;
      }
    }
  }
  th {
    height: 40px;
    font-weight: 500;
    &:last-of-type {
      padding: 4px 10px;
      width: 2.143rem;

      @media (min-width: 1500px) {
        width: 0;
        padding-right: 12px;
      }

      .routes-dropdown {
        display: inline;
      }
    }
    &.table__check {
      background-color: $primary;
    }
  }
  td {
    height: 50px;

    &.light {
      color: $gray-dark;
      font-size: $text-12;
      i {
        margin-right: 7px;
      }
      .d-flex {
        align-items: center;
      }
    }
    i {
      font-size: $text-16;
    }
    .input__action {
      i {
        font-size: $text-20;
        margin: 4px 0 0 10px;
        &:hover {
          cursor: pointer;
          color: $primary-dark;
        }
      }
    }
  }

  &__users-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .header__action {
      margin-right: 5px;
    }
  }

  &__dropdown-header {
    button {
      border: none;
      color: $gray-medium;
      transition: all 0.2s ease;

      &:hover,
      &:active {
        color: $white;
        background-color: transparent;
      }
    }
  }

  &__project-column {
    padding-left: 0px;
  }

  &__header-star {
    padding-right: 0px;
    margin-right: 9px;
    width: 10px;

    padding-right: 0px;

    &--active {
      i {
        color: $primary-light;
        font-weight: 700;
      }
    }

    i {
      color: $gray-medium;
      font-size: $text-16;
      margin-left: 6px;

      &:hover {
        color: rgba(239, 242, 244, 0.5);
        font-weight: 700;
      }

      &--active {
        color: $primary-light;
        font-weight: 700;
      }
    }
  }

  &__tag-header {
    width: 19rem;
    div {
      display: flex;
      align-items: center;
    }

    button {
      background-color: transparent;
      border: none;
      color: $gray-medium;
      i {
        &:hover {
          font-weight: 900;
        }
      }
    }
    .tag-dropdown {
      z-index: 1;
      min-width: 150px;

      .tag-list {
        width: 100%;
        height: 100%;

        .popup__select {
          overflow: unset;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            color: $gray-text;
            font-weight: 700;
            font-family: $font-family;
          }
        }
      }
    }
  }

  &__zero-state {
    text-align: center;
    height: 80vh;

    td {
      pointer-events: none;
      background-color: $white;
    }

    div {
      margin-bottom: 190px;
    }

    svg {
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 700;
      font-size: $text-16;
      color: $gray-text;
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(lg) {
      td {
        width: 100vw;
      }

      div {
        margin-top: 70px;
      }
    }
  }

  &__documents-header {
    width: $text-63;
    padding-left: 0;
  }

  &__members-header {
    width: 16rem;
  }

  &__organizers-header {
    width: 16.5rem;
  }
  &__time-header {
    width: $text-63;
  }

  &__sort {
    display: -webkit-flex;
    display: -ms-flex;
    display: inline-flex;
    align-items: center;
    button {
      color: $primary-light;
      margin-left: 9px;
    }
  }
  p {
    margin: 0;
  }

  .disabled {
    color: #e5ebf1;
  }

  &__star {
    button {
      background: none;
      border: none;

      @include media-breakpoint-up(lg) {
        &:hover {
          i {
            color: $yellow;
            font-weight: 900;
          }
        }
      }
    }

    .starred {
      color: $yellow;
      font-weight: 900;
    }

    i {
      color: $gray-medium;
      margin-right: 8px;
    }
  }

  &__status {
    position: relative;
    &--wait {
      color: $primary;
    }
    i {
      font-family: 'Font Awesome 5 Pro';
      color: $primary;
      font-size: $text-16;
      font-weight: 400;
    }
    &--tooltip {
      i {
        color: $orange;
      }

      span {
        background: $white;
        border-radius: 2px;
        position: absolute;
        text-align: center;
        right: 30px;
        font-weight: 400;
        padding: 6px 5px;
        border: solid 1px $gray-dark;
        font-size: $text-10;
        margin-top: 20px;
      }
    }
  }
}

.grid {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  &__header {
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    .routes-dropdown__toggler {
      background-color: $secondary;
      color: $white;
      i {
        font-size: $text-16;
        &:before {
          vertical-align: middle;
        }
      }
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    .grid__star {
      padding: 0 20px 0 0;

      &--active {
        i {
          color: $primary-light;
          font-weight: 700;
        }
      }
    }

    .header__action {
      .tag-dropdown {
        .tag-list {
          max-width: 150px;
          .first-letter-capital {
            color: $gray-text;
            font-weight: 700;
            font-size: $text-10;
            max-width: 75px;
          }
        }
      }
    }
  }
  &__row {
    display: flex;
    background-color: $gray-light;
    border-bottom: 1px solid rgba(175, 196, 212, 0.3);
  }
  &__action {
    display: flex;
    align-items: center;
    .check-main {
      margin-right: 5px;
    }
  }
  &__tag {
    padding-right: 0;
    display: inline;
    margin-right: 7px;
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 7px;
    p {
      margin-bottom: 5px;
    }
  }
  &__star {
    padding: 10px 0 10px 12px;
    button {
      background-color: transparent;
      border: none;
      padding-left: 0;
    }
    i {
      color: $gray-medium;
    }
  }
  &__date {
    font-size: $text-10;
    font-weight: 300;
    margin-bottom: 5px;
    color: $gray-dark;
  }
  &__document {
    font-size: $text-10;
    color: $gray-dark;
    padding: 14px 12px 10px 0;
    i {
      margin-right: 6px;
    }
  }
  &__info {
    padding: 10px 12px;
    min-width: 62px;
    font-size: $text-12;
    color: $gray-dark;
    i {
      font-size: $text-16;
    }
  }

  &__small {
    font-size: 11px;
    margin-bottom: 3px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &--light {
      color: rgba($gray-text, 0.5);
    }
  }
  .checkbox {
    label {
      display: flex;
      align-items: center;
    }
    span {
      font-size: $text-12;
      line-height: 1;
      font-weight: 500;
      color: $gray-medium;
      margin: 2px 0 0 7px;
      cursor: pointer;
    }
  }
  .header__inner {
    padding-right: 14px;
    &:after {
      content: '';
      height: 30px;
      width: 1px;
      background-color: rgba(167, 215, 255, 0.4);
      margin-left: 15px;
    }
  }
}
