@import 'sass/exports/abstracts';

.archive-table {
  thead {
    tr {
      &:hover {
        background-color: $primary;
      }
    }
    .archive-table__header {
      padding-left: 0px;
    }
  }

  tr {
    &:hover {
      .archive-table__user-check {
        background: rgba(239, 242, 244, 0.6);
      }
      .table__check-wrap {
        background: rgba(225, 232, 237, 1);
      }
    }
    &.archive-table__new {
      display: none;
      background-color: $blue;
      border: none;
      &:hover {
        cursor: default;
      }
      td {
        height: 40px;
        padding-right: 14px;
      }
      input {
        padding: 6px 30px 6px 10px;
        border-radius: 4px;
        width: 100%;
        background-color: $white;
        outline: none;
        border: 1px solid $gray-medium;
        &:placeholder-shown {
          background-color: rgba(255, 255, 255, 0.5);
          border: 1px dashed $primary;
        }
        &:focus {
          background-color: $white;
          outline: none;
          border: 1px solid $gray-medium;
        }
      }
    }
    &.archive-table__action {
      background-color: $blue;
      border: none;
      td {
        height: 40px;
        cursor: default;
      }
    }
  }

  .table__zero-state {
    background-color: $white;
    pointer-events: none;

    p {
      font-weight: 400;
      font-size: $text-12;
      line-height: 17px;
      color: $gray-dark;
    }

    a {
      text-decoration: underline;
      font-weight: 500;
      color: $gray-text;
      transition: all 0.2s ease;

      &:hover {
        color: lighten($gray-text, 30%);
      }
    }

    &:hover {
      background-color: $white;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
  }

  .header__action {
    border: none;
    color: $white;
    transition: all 0.2s ease;
    width: 0;
    min-width: auto;
    padding: 0 5px 0 6px;
    &:hover,
    &:active {
      color: $gray-medium;
      background-color: transparent;
    }
  }

  th,
  td {
    padding: 0 16px 0 9px;
    white-space: nowrap;
    &.table__check {
      min-width: 34px;
      padding: 0 0 0 4px;
      text-align: center;
    }

    span {
      i {
        margin-left: 7px;
      }
    }

    &.archive-table {
      &__header {
        padding: 0 4px 0 12px;
        width: 0;
      }
      &__dropdown-header {
        padding-right: 12px;
      }
      &__project-header {
        width: 18rem;
        padding-left: 0;
      }
      &__project-date-header {
        width: 11.571rem;
      }
      &__organizers-header {
        width: 16.5rem;
      }
      &__members-header {
        width: 16rem;
        padding-left: 0;
      }

      &__tags-header {
        width: 19rem;

        button {
          background-color: transparent;
          border: none;
          color: $gray-medium;
          i {
            &:hover {
              font-weight: 900;
            }
          }
        }

        .tag-dropdown {
          z-index: 1;
          min-width: 150px;
          .tag-list {
            width: 100%;
            height: 100%;

            .popup__select {
              overflow: unset;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              span {
                color: $gray-text;
                font-weight: 700;
                font-family: $font-family;
              }
            }
          }
        }
      }
    }
  }

  th {
    height: 40px;
    font-weight: 500;
    &:last-of-type {
      padding-right: 12px;
      width: 0;

      .routes-dropdown {
        display: inline;
      }
    }
    &.table__check {
      background-color: $primary;
    }
  }

  td {
    height: 50px;
    &.table__project-title {
      padding-left: 0;
    }
    &.light {
      color: $gray-dark;
      font-size: $text-12;
      i {
        margin-right: 7px;
      }
      .d-flex {
        align-items: center;
      }
    }
    i {
      font-size: $text-16;
    }
    .input__action {
      i {
        font-size: $text-20;
        margin: 4px 0 0 10px;
        &:hover {
          cursor: pointer;
          color: $primary-dark;
        }
      }
    }
    .archive-table__inner {
      position: relative;
      i {
        font-size: $text-20;
      }
      .edit-close {
        position: absolute;
        right: -16px;
        bottom: 3px;
        &:hover {
          cursor: pointer;
          i {
            color: $primary-dark;
          }
        }
      }
    }
  }

  &__sort {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;

    .archive-table__project-date-filter {
      top: 21px;
      right: 1px;
      position: absolute;
      width: 110px;
      background-color: $white;
      border: solid 1px $gray-dark;
      border-radius: 2px;
      color: $gray-dark;
      font-size: $text-10;
      padding: 6px 7px;
      font-weight: 400;
      text-transform: none;
      white-space: normal;
    }

    button {
      background-color: transparent;
      border: none;
      color: $gray-medium;
      font-size: $text-12;
      font-weight: 500;
    }
    a {
      color: $primary-light;
      margin-left: 9px;
    }
  }
  &__star {
    a {
      &:hover {
        i {
          color: $yellow;
          font-weight: 900;
        }
      }
    }
    i {
      color: $gray-medium;
      margin-right: 8px;
    }
    .starred {
      color: $yellow;
      font-weight: 900;
    }
  }
  p {
    margin: 0;
  }

  .disabled {
    color: #e5ebf1;
  }
}

.grid {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  &__header {
    .grid__inner {
      .header__action {
        .tag-list {
          max-width: 150px;
          .tag {
            color: $gray-text;
            font-weight: 700;
            font-size: $text-10;
            max-width: 90px;
          }
        }
      }
    }
  }
}
