@import 'sass/exports/abstracts';

.datepicker {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex-direction: column;

  &__inner {
    display: flex;
    position: relative;

    .react-datepicker-popper {
      background-color: $white;
      margin-top: 10px;
      width: 306px;
      transform: translate(0px, 20px) !important;
    }
    .react-datepicker__current-month {
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .react-datepicker__input-container {
      max-width: 155px;

      .end-date {
        max-width: 151px;
        padding: 5px 20px 5px 16px;
      }
      .start-date {
        max-width: 155px;
      }
      &:focus-within {
        color: $primary;

        &:before {
          color: $primary;
        }
        input {
          border-color: $primary;
        }
      }

      input {
        height: 30px;
        border: 1px solid $primary;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 5px 20px 5px 8px;
        color: $primary;
        outline: none;
        font-size: $text-14;
        @include media-breakpoint-down(sm) {
          max-width: 160px;
        }
        &::placeholder {
          color: $gray-medium;
        }

        &:placeholder-shown {
          border: 1px solid $cadet-blue;
        }
        &:focus-within {
          color: $primary;
          border: 1px solid $primary;
        }
      }

      &::before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        content: '\f073';
        font-size: $text-16;
        position: absolute;
        top: 7px;
        left: unset;
        right: 8px;
        pointer-events: none;
      }
    }
  }

  &__actions {
    width: 305px;
    padding: 10px 17px;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 2px 2px;
    display: flex;
    justify-content: flex-end;
    .datepicker__reset {
      font-weight: 400;
      cursor: default;
      text-decoration: underline;
      color: $gray-medium;
      padding: 0;
      border: none;
      background: transparent;
      &--active {
        color: $primary;
        cursor: pointer;
      }
    }
  }
  &__wrap {
    .react-datepicker {
      &__input-container {
        &:before {
          color: $primary;
        }
      }
      &-wrapper {
        &:before {
          border-color: $primary;
        }
      }
    }
  }
  &__arrow {
    .react-datepicker-wrapper {
      position: relative;
      &:before,
      &:after {
        left: calc(100% - 1px);
        top: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;
      }
      &:before {
        border-color: rgba(172, 182, 191, 0);
        border-left-color: $gray-medium;
        border-width: 15px;
        margin-top: -15px;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: $white;
        border-width: 14px;
        margin-top: -14px;
      }
      &:focus-within {
        &::before {
          border-left-color: $primary;
        }
      }
    }
    &.datepicker__wrap {
      .react-datepicker-wrapper {
        &:before {
          border-left-color: $primary;
        }
      }
    }
  }
  &__position {
    margin-right: -30px;
  }
}

.react-datepicker {
  font-family: $font-family;
  width: 100%;
  border: none;
  background: $gray-light;
  border-radius: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &-popper {
    width: 100%;
    transform: translate(0px, 72px) !important;
  }
  &__month-container {
    width: 100%;
  }
  &-wrapper {
    .popup__body & {
      input {
        border: none;
        margin: 0;
        color: $gray-dark;
        font-size: $text-12;
        padding-left: 30px;
        &::-webkit-input-placeholder {
          font-size: $text-12;
        }
        &::-moz-placeholder {
          font-size: $text-12;
        }
        &:-ms-input-placeholder {
          font-size: $text-12;
        }
        &:-moz-placeholder {
          font-size: $text-12;
        }
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        &:focus {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }
  &__input-container {
    position: relative;
    color: $gray-medium;
    &:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f073';
      font-size: $text-16;
      position: absolute;
      top: 8px;
      left: 5px;
      pointer-events: none;
      color: $gray-medium;
    }
  }
  &__month {
    padding-bottom: 10px;
  }
  &__triangle {
    display: none;
  }
  &__header {
    padding: 20px 0 8px;
    border: none;
    background: $gray-light;
  }
  &__current-month {
    font-size: $text-14;
    color: $gray-dark;
    font-weight: 500;
    margin-bottom: 15px;
  }
  &__navigation {
    top: 16px;
    &--previous {
      left: 20px;
    }
    &--next {
      right: 20px;
    }
    &-icon {
      &::before {
        border-width: 2px 2px 0 0;
        border-color: $gray-medium;
      }
    }
  }
  &__day {
    width: 24px;
    height: 24px;
    line-height: 22px;
    border-radius: 2px;
    border: 1px solid transparent;
    color: $gray-dark;
    font-weight: 500;
    font-size: $text-12;
    margin: 3px 6px;
    &:hover {
      border-radius: 2px;
      background-color: $primary;
      color: $white;
    }
    &-name {
      text-transform: uppercase;
      color: $gray-dark;
      font-weight: 500;
      font-size: $text-12;
      margin: 3px 6px;
    }
    &--today {
      background-color: $white;
      color: $primary;
      border-color: $primary;
      font-weight: 700;
    }
    &--outside-month {
      color: $gray-medium;
    }
    &--keyboard-selected {
      background-color: transparent;
    }
    &--selected {
      color: $white;
      font-weight: 700;
      border-radius: 2px;
      background-color: $primary;
      color: $white;
    }
  }
}
