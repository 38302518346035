@import '/src/sass/exports/abstracts';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $gray-dark;
  z-index: 998;
  &__backdrop {
    background: rgba(35, 39, 48, 0.7);
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-items: flex-start;
      padding-top: 30px;
    }
  }
  &__content {
    cursor: default;
    max-height: calc(100vh - 3.5rem);
    overflow: auto;
    @include media-breakpoint-down(md) {
      max-height: calc(100vh - 30px); // Spacing from top of the browser
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-medium;
      border-radius: 15px;
    }
    &--overflow {
      overflow: visible;
    }
  }
}
