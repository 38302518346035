@import 'sass/exports/abstracts';

.mobile-sort {
  background-color: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 14px 14px 0 12px;
  min-width: 165px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    display: block;
  }

  button {
    background-color: transparent;
    color: $gray-dark;
    outline: none;
    border: none;
    font-weight: 400;
    margin-bottom: 10px;
  }
}
