@import 'sass/exports/abstracts';

.project {
  &__content {
    @include media-breakpoint-up(md) {
      min-width: 700px;
    }
    &--day {
      padding: 31px 21px 15px;
      min-height: 200px;
      .btn-close {
        top: 5px;
        right: 5px;
      }
    }
  }
  &__preview {
    border: 1px solid $gray-medium;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 11px;
  }
  &__preview-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .event-info__star--starred {
      -webkit-text-stroke: 1px $gray-medium;
    }
  }
  &__preview-header {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    justify-content: space-between;
    background: rgba(3, 121, 191, 0.2);

    h2 {
      font-size: $text-16;
      margin: 2px 0 0 8px;
      line-height: 1;
    }
  }
  &__preview-content {
    padding: 15px 8px 0;
    @include media-breakpoint-down(sm) {
      padding-bottom: 15px;
    }
    p {
      margin-bottom: 15px;
      color: $gray-text;
      @include media-breakpoint-down(sm) {
        margin-bottom: 7px;
      }
    }
  }
  &__preview-text {
    p {
      max-width: 340px;
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 20px;
      color: $gray-text;
      position: relative;
      .truncate-note {
        position: absolute;
        top: 1px;
        right: 0;
      }
    }
  }
}
