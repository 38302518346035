.primary {
  color: $primary;
  &:hover {
    color: $primary-dark;
  }
}

.warning {
  color: $orange;
  &:hover {
    color: darken($orange, 10%);
  }
}

.required {
  color: $secondary;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-md-25 {
  @include media-breakpoint-up(md) {
    margin-bottom: 25px !important;
  }
}

.text-right {
  text-align: right;
}

.first-letter-capital {
  &::first-letter {
    text-transform: capitalize;
  }
}
