@import 'sass/exports/abstracts';

.date-placeholder {
  font-size: $text-12;
  font-weight: 400;
  color: $gray-dark;
  opacity: 1;
}

.date-dropdown {
  z-index: 100;

  &__divider {
    width: 10px;
    height: 1px;
    margin: 0 6px;
    background: $gray-dark;
  }

  &__actions {
    margin-top: 6px;

    button {
      max-width: 90px;
      text-transform: capitalize;
    }
    .btn--primary {
      margin-right: 10px;
    }
  }

  &__date {
    .react-datepicker-popper[data-placement^='bottom'] {
      padding-top: 0;
    }
    .react-datepicker__current-month {
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .react-datepicker__day--disabled {
      opacity: 0.3;
    }

    .react-datepicker__input-container {
      &::before {
        color: $gray-dark;
      }
      input {
        color: $gray-dark;

        &::placeholder {
          @extend .date-placeholder;
        }
      }
    }

    .react-datepicker__date-picked {
      color: $gray-text !important;

      &::placeholder {
        color: $gray-text !important;
      }
    }
  }

  &__time {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-top: 2px;

    .react-datepicker.react-datepicker--time-only {
      margin-top: 3px;
    }

    i {
      font-size: $text-16;
      margin-right: 5px;
      color: $gray-dark;
    }

    input {
      width: 55px;
      border-radius: 2px;
      border: transparent;
      padding: 8px 3px;
      text-align: center;
      transition: all 0.2s ease;

      &::placeholder {
        @extend .date-placeholder;
      }

      &:hover {
        background: $gray-light;
        text-decoration: underline;
        cursor: pointer;
      }

      &:active,
      &:focus {
        color: $primary;
        text-decoration: underline;
        outline: none;
      }
    }
    .react-datepicker__input-container:before {
      content: '';
      color: $gray-dark !important;
    }

    .react-datepicker {
      width: auto;
      margin-top: 30px;
    }
  }

  &__time-from {
    .react-datepicker__time-container {
      position: absolute;
      left: 18px;
      top: 5px;
    }
  }

  &__time-to {
    .react-datepicker__time-container {
      position: absolute;
      left: 95px;
    }
  }

  &__time-from,
  &__time-to {
    .react-datepicker__time-list-item {
      color: $gray-dark;
      font-size: $text-12;
      font-weight: 400;
      color: $gray-dark;

      &--selected {
        color: $primary !important;
        background-color: transparent !important;
      }
    }

    .react-datepicker__time-box {
      background: $gray-light;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
    }

    .react-datepicker__time-list {
      &::-webkit-scrollbar {
        width: 3px;
        height: 21px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-medium;
        border-radius: 90px;
      }
    }

    .react-datepicker__header {
      display: none;
    }
  }

  .popup__body .react-datepicker-wrapper input {
    font-size: $text-12;
    font-family: $font-family;
    font-weight: 400;
    color: $gray-dark;
    padding-left: 40px;
    transition: all 0.2s ease;
    text-transform: capitalize;

    &::placeholder {
      @extend .date-placeholder;
    }

    &:hover {
      background: $gray-light;
      text-decoration: underline;
      cursor: pointer;
    }

    &:active,
    &:focus {
      color: $primary;
      text-decoration: underline;
      outline: none;
    }
  }
}
