@import 'sass/exports/abstracts';

.sort-icon {
  button {
    background-color: transparent;
    border: none;
    color: $gray-medium;
    font-size: $text-12;
    font-weight: 500;
  }
}

.tag-table {
  background-color: $white;

  @include media-breakpoint-down(lg) {
    .popup__body {
      label {
        text-transform: capitalize;
      }
    }
  }

  &__divider {
    height: 25px;
    width: 1px;
    background-color: $primary-light;
    margin-left: 10px;
    margin-right: 15px;
  }

  &--desktop {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &--mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__mobile-tag {
    margin-right: 20px;
  }

  .table__zero-state {
    margin-top: 80px;
    background-color: $white;
    pointer-events: none;

    p {
      font-weight: 400;
      font-size: $text-12;
      line-height: 17px;
      color: $gray-dark;
    }
  }

  &__head {
    color: $gray-medium;
    text-transform: uppercase;
    font-size: $text-12;
    font-weight: 500;
    padding: 20px 0;

    tr {
      background-color: $primary;
      border-right: none;
      width: 100%;
      .tag-table__checkbox {
        .checkbox {
          display: inline;
        }
      }
      &:hover {
        background-color: $primary;
        cursor: default;
      }
    }
  }

  &__mobile-project-count {
    font-size: 12px;
    padding-right: 23px;

    i {
      color: $gray-medium;
    }

    span {
      color: $gray-dark;
    }
  }

  &__row {
    width: 100%;
    border-bottom: 1px solid rgba(175, 196, 212, 0.3);
    height: 50px;
    background-color: $gray-light;
    &:hover {
      background: $white;
      cursor: pointer;
    }
    &-date {
      color: $gray-dark;
      font-size: $text-14;
      font-weight: 400;
    }

    &-checkbox {
      padding: 0 10px 0 13px;
      input {
        width: 18px;
        height: 18px;
      }
    }
    &-project {
      padding-left: 13px;
    }
    &-tag {
      border-radius: 2px;
      max-height: 18px;
      padding: 5px 5px 5px 5px;
      font-size: $text-10;
      font-weight: 700;
      text-align: center;
      margin-right: 25px;
    }
    &-icon {
      margin-bottom: 1px;
      padding-left: 13px;

      &-edit {
        border: none;
        background-color: $gray-base;
        border-radius: 2px;
        padding: 2px 4px;
        color: $primary-light;
      }

      .popup-body__name {
        display: flex;
        flex-wrap: wrap;
        font-family: $font-family;
        color: $gray-dark;
        font-weight: 400;
        font-size: $text-14;

        .popup-body__tag-label {
          margin-top: 10px;
        }

        input {
          margin-bottom: 10px;
          margin-top: 4px;
          color: $gray-text;
        }
      }
    }
  }

  &__body {
    padding: 0 12px;
    min-height: 100%;
    td {
      height: 50px;
    }
    .tag-table {
      &__row {
        width: 100%;
        border-bottom: 1px solid rgba(175, 196, 212, 0.3);
        height: 50px;
        &-date {
          color: $gray-dark;
          font-size: $text-14;
          font-weight: 400;
        }

        &-checkbox {
          padding: 0 0 0 12px;
        }
        &-project {
          padding-left: 13px;
          @media (min-width: '1700px') {
            padding-left: 7px;
          }
        }
        &-tag {
          border-radius: 2px;
          max-height: 18px;
          padding: 5px 5px 5px 5px;
          font-size: $text-10;
          font-weight: 700;
          text-align: center;
          margin-right: 25px;
        }
        &-icon {
          margin-bottom: 1px;
          padding-left: 6px;
          display: flex;
          height: 50px;
          align-items: center;
          font-size: $text-14;
          font-weight: 400;
          &-edit {
            border: none;
            background-color: $gray-base;
            border-radius: 2px;
            padding: 2px 4px;
            color: $primary-light;

            &--active {
              color: $gray-dark;
              background-color: rgba(167, 215, 255, 0.5);
            }
          }

          .popup-body__name {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
  th,
  td {
    &.tag-table {
      &__row {
        &-checkbox {
          padding-left: 5px;
        }
      }
      &__menu-header {
        width: 40px;
        padding: 5px 12px 5px 0;
        .routes-dropdown__nav {
          margin-top: 3px;
        }
      }

      &__add-icon-header {
        width: 0;
        text-align: right;

        input[type='text']#badge_name {
          margin-top: 4px;
          margin-bottom: 10px;
        }

        .popup-body {
          &__name {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: $font-family;
            color: $gray-dark;
            font-weight: 400;
            font-size: $text-12;

            label {
              text-transform: none;
              font-weight: 400;
            }
          }

          &__tag-label {
            margin-top: 10px;
          }
        }
        .badge__label {
          i {
            position: relative;
            top: 13px;
            left: -13px;
          }
        }
      }

      &__checkbox {
        padding: 0 0 0 13px;
        width: 45px;
      }
      &__dropdown-header {
        padding: 0px;
        width: 10px;

        .header__action {
          min-width: auto;
          padding: 0 1px 0 3px;
          margin-left: -8px;
          border: none;
          color: rgba(167, 215, 255, 0.4);
          transition: all 0.2s ease;
          &:hover {
            color: $gray-medium;
            background-color: transparent;
          }
        }
      }
      &__project-header {
        padding-left: 13px;
        width: 34.714rem;

        @include media-breakpoint-up(xxl) {
          padding-left: 13px;
        }

        @media (min-width: '1700px') {
          padding-left: 5px;
        }

        @include media-breakpoint-between(lg, xl) {
          padding-left: 13px;
        }
        span {
          margin-right: 8px;
        }
        @extend .sort-icon;
      }

      &__tags-header {
        margin-top: 12px;
        display: flex;
        align-items: center;
        padding-left: 7px;
        span {
          margin-right: 8px;
        }
        @extend .sort-icon;
      }
    }

    white-space: nowrap;
  }

  th {
    font-weight: 500;
  }

  &__icon {
    background-color: $primary;
    color: $white;
    padding: 3px 7px 3px 12px;
    font-size: $text-16;
    border: 1px solid $blue_light;
    border-radius: 2px;
    position: relative;
    height: 30px;
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    &:hover {
      background-color: rgba(239, 242, 244, 0.3);
    }
    i {
      &:before {
        vertical-align: middle;
      }
    }

    &::before {
      content: '+';
      font-size: $text-10;
      font-weight: 700;
      position: absolute;
      left: 5px;
      top: 3px;
    }

    &--open {
      background-color: $blue_lightest;
      &:hover {
        background-color: $blue_lightest;
      }
    }

    &--regular {
      padding: 5px 3px;
      width: 30px;

      &::before {
        content: '';
      }
    }
  }
}
