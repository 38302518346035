@import '/src/sass/exports/abstracts';

.institution-editor {
  background-color: $white;
  padding: 13px 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  @include media-breakpoint-down(lg) {
    width: 340px;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $gray-medium;
    padding-bottom: 13px;

    h3 {
      font-weight: 700;
      font-size: $text-14;
      text-transform: uppercase;
      color: $gray-medium;
    }
  }

  &__header-close {
    position: absolute;
    right: 10px;

    i {
      color: $gray-dark;
    }
  }

  &__name {
    margin-top: 10px;

    input {
      border: 2px solid $gray-dark;
      outline: none;
      margin-bottom: 7px;
      padding: 7px 12px;
      border-radius: 2px;
      font-size: $text-14;
      width: 100%;

      &::placeholder {
        color: $gray-medium;
      }
    }
  }

  &__upload {
    background: $gray-light;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin-bottom: 13px;
  }

  &__upload-icon {
    margin-top: 12px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: $primary-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    span {
      font-size: $text-10;
    }

    i {
      font-size: 2.375rem;
    }
  }

  &__upload-message {
    text-align: center;
    border: 1px dashed $primary-light;
    border-radius: 2px;
    padding: 12px;
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;

    h3 {
      color: $gray-medium;
      font-size: $text-14;
      font-weight: 400;
      margin-bottom: 5px;
    }

    span {
      color: $gray-text;
      font-size: $text-12;
      font-weight: 400;
      text-decoration: underline;
      display: block;
      cursor: pointer;
    }
  }

  &__upload-info {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    margin-top: 11px;

    h3 {
      font-weight: 300;
      font-size: $text-12;
      color: $gray-dark;
      font-style: italic;
      line-height: 17px;
    }

    span {
      display: block;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    outline: none;
    background: $primary;
    border-radius: 2px;
    border: transparent;
    color: $white;
    padding: 7px 10px;
    margin-right: 14px;
    font-weight: 500;
    transition: all 0.2s ease;

    span {
      display: block;
    }

    &:hover {
      background: darken($primary, 7%);
    }
  }

  &__close {
    outline: none;
    border: transparent;
    background-color: transparent;

    i {
      font-size: 25px;
      color: $gray-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      i {
        color: darken($gray-dark, 10%);
      }
    }
  }
}
