@import 'sass/exports/abstracts';

.popup {
  padding: 11px 7px 10px;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 305px;
  font-size: $text-12;
  z-index: 1;
  .project-remainder {
    &__wrapper {
      .paragraph {
        margin: 9px 0 5px 0;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 10px;
      p {
        margin: 5px 0 10px 0;
        font-weight: 300;
        font-style: italic;
        line-height: 14px;
      }
      .btn--primary {
        height: 30px;
        width: 80px;
        background-color: $primary;
        color: $white;
        border-color: $primary;
        font-weight: 500;
        text-transform: capitalize;
      }
      .btn--secondary {
        margin-left: 10px;
        font-weight: 500;
        text-align: center;
        width: 80px;
        background-color: transparent;
        border: solid 0.1px;
        color: $primary;
        border-color: $primary;
        text-transform: capitalize;
      }
    }
    &__notifications {
      display: flex;
      flex-direction: column;
      label {
        margin: 5px 0;
        display: flex;
        input {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          font-size: $text-14;
        }
        p {
          height: 20px;
          margin: 0 20px -10px 0;
        }
        .error {
          width: 200px;
          font-size: $text-9;
        }
      }
    }
  }

  &__header {
    text-align: center;
    border-bottom: 1px solid $gray-medium;
    position: relative;

    .btn-close {
      top: -2px;
      right: 2px;
    }

    &--close {
      .btn-close {
        width: 20px;
        height: 20px;
        right: 10px;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }

    .back {
      position: absolute;
      top: 1px;
      left: 7px;
      color: $gray-medium;
      &:hover {
        color: $gray-dark;
      }
    }
  }
  &__body {
    padding: 5px 0;
    border-bottom: 1px solid $gray-medium;
    .add-remainder-wrapper {
      display: flex;
      margin: 9px 0 13px 0;
      p {
        min-width: 50px;
        height: 25px;
        margin: 0;
      }
    }

    h3 {
      font-weight: 500;
      color: $gray-dark;
    }

    p {
      margin: 5px 0 0 0;
      &.remainder-date {
        font-weight: 400;
        color: $gray-text;
        span {
          margin: 3px 7px 3px 0;
          font-weight: bold;
          line-height: 14px;
          color: $gray-text;
        }
      }
    }

    .remainders-dropdown {
      max-height: 100px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 100%;
      }

      &::-webkit-scrollbar-track {
        background: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-medium;
        border-radius: 100vw;
      }

      &__list {
        margin: 0;
      }

      &__add-remainder {
        width: 200px;
        span {
          font-size: $text-21;
          margin: 8px 5px 8px 0;
        }
      }
      &__item {
        display: flex;
        p {
          margin-right: 7px;
        }
        button {
          width: 10px;
          height: 10px;
          padding-top: 11px;
        }
      }
    }

    .remainder-time {
      border: 1px solid $gray-medium;
      width: 55px;
      height: 25px;
      border-radius: 2px;
      .react-datepicker__input-container {
        width: 50px;

        input {
          padding-left: 5px;
          height: 23px;
          width: 45px;
          &:focus {
            color: black;
          }
        }
        &::before {
          content: '';
        }
      }
      .react-datepicker__time {
        width: 70px;
      }

      .react-datepicker--time-only {
        width: 0;
        position: absolute;
        right: 55px;
        top: 40px;
        .react-datepicker__header--time--only {
          display: none;
        }

        .react-datepicker__time-box {
          width: 60px;
        }

        .react-datepicker__time-list {
          &::-webkit-scrollbar {
            width: 5px;
            height: 100%;
          }

          &::-webkit-scrollbar-track {
            background: $white;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $gray-medium;
            border-radius: 100vw;
          }

          &-item {
            padding-right: 0;
            width: 50px;
            margin-right: 0;
          }
        }
      }
    }
    button {
      background-color: transparent;
      font-weight: 400;
      font-size: $text-12;
      line-height: 14px;
      height: 20px;
      padding-left: 0;
      &:hover {
        background-color: transparent;
      }
    }
    &--borderless {
      border-bottom: none;
      padding-bottom: 0;
    }
    .remainder-days {
      margin: 0;
      width: 35px;
      height: 25px;
      border: 1px solid $gray-medium;
      padding: 3px;
      margin-right: 5px;
      &__wrapper {
        position: relative;
        margin: 0;

        &::after,
        &::before {
          position: absolute;
          padding: 0;
          right: 5px;
          width: 20px;
          height: 5px;
          font-size: $text-12;
          pointer-events: none;
          background-color: transparent;
        }
        &::after {
          width: 10px;
          height: 10px;
          font-family: 'Font Awesome 5 Pro';
          color: $gray-dark;
          font-weight: 900;
          content: '\f0dd';
          top: 6px;
          right: 12px;
          text-align: center;
        }
        &::before {
          width: 10px;
          height: 10px;
          font-family: 'Font Awesome 5 Pro';
          content: '\f0de';
          font-weight: 900;
          color: $gray-dark;
          right: 12px;
          top: 5px;
          text-align: center;
        }
      }
    }
    .remainder-time-periods {
      min-width: 70px;
      height: 25px;
      border: 1px solid $gray-medium;
      border-radius: 2px;
      font-size: $text-12;
      padding-top: 4px;
      margin-right: 5px;

      &:focus {
        outline: 1px solid $primary;
      }

      span {
        padding: 5px 0 10px 5px;
        margin-right: 10px;
      }
      i.fa-sort-down {
        position: absolute;
        left: 101px;
      }

      .periods {
        margin: 7px 10px 0 0;
        &__single {
          width: 70px;
          height: 25px;
          border: 1px solid $gray-medium;
          background-color: $white;
          padding: 2px 10px 0 10px;
          position: relative;
          z-index: 1;
          right: 1px;
        }
      }
    }
    ul {
      @extend %reset-list;
      margin: 0 0 0 10px;
      &.popup__select {
        overflow: auto;
        margin-top: 9px;
        max-height: 250px;
        position: relative;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-medium;
          border-radius: 15px;
        }
        &--overflow {
          overflow: visible;
        }

        li {
          display: flex;
          justify-content: space-between;
          .name {
            font-weight: 400;
            font-size: $text-14;
            color: $gray-dark;
          }
          small {
            .email {
              font-weight: 400;
              font-size: $text-12;
              color: $gray-dark;
            }
          }

          span {
            font-weight: 400;
            margin-left: auto;
            &:hover {
              text-decoration: none;
            }
            &.popup__select__check {
              color: $primary-light;
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 2px;
              &:hover {
                background-color: $gray-base;
              }
              i {
                margin-top: 2px;
                color: $primary-light;
              }
            }
          }
          small {
            display: block;
            font-size: $text-12;
            font-weight: 400;
            color: $gray-medium;
          }
        }
      }
      li {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a,
      span {
        cursor: pointer;
        font-weight: 500;
        color: $gray-dark;
        &:hover {
          color: $gray-text;
          text-decoration: underline;
        }
        &.tag-more {
          color: $primary-light;
          &:hover {
            color: $gray-dark;
          }
        }
      }
    }
    label.badge__label {
      display: block;
      margin-bottom: 4px;
      font-size: $text-12;
      i.fa-check {
        position: relative;
        top: 13px;
        left: 13px;
      }
    }
    input {
      width: 100%;
      height: 35px;
      border: 2px solid $gray-dark;
      border-radius: 2px;
      margin-bottom: 15px;
      margin-top: 10px;
      padding-left: 14px;

      &:focus {
        outline: none;
      }
    }
  }
  &__footer {
    padding: 7px 2px 0 10px;
    font-size: $text-12;
  }
  &__button {
    margin: 13px 0 3px;
    .dropdown__toggler {
      width: 100%;
    }
    .btn {
      display: block;
      color: $white;

      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }
  }
}
