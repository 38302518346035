.project-preview-members {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__badge {
    margin-left: 2px;
  }

  p {
    padding-right: 0px;
    margin-bottom: 0px;
  }
}
