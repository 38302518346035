@import 'sass/exports/abstracts';

.user-row-mobile {
  &__icon {
    i {
      font-weight: 400;
      color: $primary;
    }
  }

  &__status {
    width: 84px;
    text-align: left;
  }

  $this: &;

  &__name {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    #{$this} {
      &__admin-label {
        font-size: 0.4375rem;
        margin-left: 7px;
        padding: 2px 4px;
        text-transform: uppercase;
        color: #0379bf;
        border: 1px solid #0379bf;
        border-radius: 2px;
      }
    }
  }
}
