@import 'sass/exports/abstracts';

.add-project {
  display: flex;
  flex-direction: column;
  padding: 13px 7px;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 305px;

  &__title {
    border: 2px solid $gray-dark;
    outline: none;
    margin-bottom: 7px;
    padding: 7px 12px;
    border-radius: 2px;
    font-size: $text-14;

    &::placeholder {
      color: $gray-medium;
    }
  }

  &__description {
    resize: none;
    border: 1px solid $gray-base;
    background: $gray-light;
    border-radius: 2px;
    padding: 7px 12px;
    margin-bottom: 13px;

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $gray-medium;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    outline: none;
    background: $primary;
    border-radius: 2px;
    border: transparent;
    color: $white;
    padding: 7px 10px;
    margin-right: 14px;
    font-weight: 500;
    transition: all 0.2s ease;

    span {
      display: block;
    }

    &:hover {
      background: darken($primary, 7%);
    }
  }

  &__close {
    outline: none;
    border: transparent;
    background-color: transparent;

    i {
      font-size: 25px;
      color: $gray-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      i {
        color: darken($gray-dark, 10%);
      }
    }
  }
}
