h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
blockquote {
  @extend %font-fix;
}

h1 {
  font-size: $text-14;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  font-size: $text-14;
  font-weight: 500;
  color: $white;
  margin: 0;
}

h3 {
  font-size: $text-12;
  font-weight: 300;
  color: $white;
  margin: 0;
  line-height: 1;
}

h4 {
  font-size: $text-14;
  font-weight: 700;
  color: $gray-medium;
  text-transform: uppercase;
}

h5 {
  font-size: $text-12;
  text-transform: uppercase;
  font-weight: 500;
  color: $gray-medium;
  margin-bottom: 3px;
}

h6 {
  font-size: $text-16;
  font-weight: 400;
  color: $gray-text;
  margin-bottom: 3px;
}

a {
  transition: 0.2s ease-in;
  text-decoration: none;
}
