.dropdown {
  &__toggler {
    display: inline-flex;
  }
  &__content {
    display: none;
    &--active {
      display: block;
    }
  }
}
