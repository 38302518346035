@import 'sass/exports/abstracts';

.confirm-organizer {
  width: 500px;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 50px;
  position: relative;

  @include media-breakpoint-down(md) {
    width: 350px;
    padding: 25px;
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    background-color: transparent;
    outline: none;
    border: transparent;
    font-size: $text-16;
    color: $gray-dark;
  }

  &__description {
    flex-wrap: wrap;
    margin-top: 1px;
  }

  &__header {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      text-align: center;
    }

    &--email-change {
      i {
        position: relative;
        top: -31px;
      }
      h3 {
        padding-bottom: 12px;
        font-size: $text-18;
      }
      p {
        font-size: $text-12;
      }
    }

    h3 {
      color: $gray-text;
      font-size: $text-16;
      line-height: 19px;
      font-weight: 400;

      span {
        font-weight: 600;
      }

      @include media-breakpoint-down(md) {
        @include media-breakpoint-down(md) {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    i {
      color: $secondary;
      font-size: 2.25rem;
      margin-right: 15px;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .btn {
      margin-right: 10px;
      text-transform: uppercase;
    }
  }
}
