@import 'sass/exports/abstracts';

.pagination {
  padding: 5px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }

  &__btn {
    i {
      font-size: 25px;
      color: $primary;
    }

    &:last-child {
      margin-left: 30px;
    }

    &[disabled] {
      i {
        color: $gray-medium;
      }
    }
  }
}
