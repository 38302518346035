@import 'sass/exports/abstracts';

.create-password-form {
  max-width: 410px;
  margin: 0 auto;
  padding: 62px 0 53px;
  @include media-breakpoint-down(md) {
    max-width: none;
    padding: 20px 25px 30px;
  }

  input {
    margin-bottom: 20px;
  }

  .form {
    &__validation {
      margin: -10px 0 10px 0;
    }
  }

  .button {
    @include media-breakpoint-down(md) {
      width: 150px;
    }

    @include media-breakpoint-down(sm) {
      min-width: 120px;
    }

    &__wrap {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    &--primary {
      @include media-breakpoint-down(sm) {
        margin-right: 5px;
      }
    }
  }
}
