@import '/src/sass/exports/abstracts';

.button {
  min-width: 199px;
  height: 40px;
  text-transform: uppercase;
  font-size: $text-16;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  border: 1px solid;
  transition: 0.2s ease-in;
  line-height: 1;

  &--primary {
    background-color: $secondary;
    color: $white;
    border-color: $secondary;

    &:hover {
      background-color: $secondary-dark;
      color: $white;
    }
  }
  &--secondary {
    background-color: transparent;
    color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  &--small {
    height: 30px;
    min-width: 173px;
    font-size: $text-14;
  }
  span {
    margin-right: 14px;
    font-size: $text-24;
    line-height: 1;
  }
  &__wrap {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-top: 25px;
    }
  }
}

.btn {
  font-weight: 500;
  padding: 7px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  border: 1px solid;
  transition: 0.2s ease-in;
  line-height: 1;
  &--primary {
    background-color: $primary;
    color: $white;
    border-color: $primary;
    &:hover {
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
  }
  &--secondary {
    background-color: transparent;
    color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  &--small {
    font-size: $text-12;
    padding: 3px 9px;
  }
  &--large {
    padding: 7px 16px;
    text-transform: uppercase;
    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--disabled {
    background-color: $gray-base;
    color: $gray-dark;
    border-color: $gray-base;
    cursor: default;
    &:hover {
      background-color: $gray-base;
      color: $gray-dark;
      border-color: $gray-base;
    }
  }
  &__wrap {
    margin: 13px 0 3px;
    display: flex;
    .btn {
      margin-right: 10px;
    }
  }

  &--link {
    background: none;
    padding: 0;
    border: 0;
    color: $link-blue;
    font-weight: 400;
  }

  &--clear {
    background: none;
    padding: 0;
    border: 0;
  }
  &--underline {
    background: none;
    padding: 0;
    border: 0;
    font-weight: 400;
    font-size: $text-12;
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
  }
  &__save {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: flex-end;
    }
  }
}

button:disabled {
  background-color: $gray-base;
  color: $gray-dark;
  border-color: $gray-base;
  pointer-events: none;
}

.btn-close {
  position: absolute;
  top: 15px;
  right: 12px;
  border: none;
  color: $gray-dark;
  background-color: transparent;
  font-size: $text-16;
  &:hover {
    color: $primary;
  }
}
