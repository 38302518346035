.form {
  &__check {
    div[role='button'] {
      display: flex;
      label {
        margin-left: 12px;
      }
    }
  }

  &__action {
    display: flex;
    margin-top: 50px;
  }
}
