@import 'sass/exports/abstracts';

.header {
  background-color: $primary;
  color: $white;

  &__wrap {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid rgba(167, 215, 255, 0.4);
    @include media-breakpoint-down(xl) {
      justify-content: space-between;
      padding: 15px 0;
    }
  }
  &__inner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding-right: 15px;
    @include media-breakpoint-down(xl) {
      padding-right: 5px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &:first-of-type {
      padding-left: 0;
      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }
    &:last-of-type {
      padding-right: 0;
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      &:after {
        display: none;
      }
    }
    &:nth-of-type(2) {
      @include media-breakpoint-up(xl) {
        flex: 1;
      }
    }
    &:after {
      @include media-breakpoint-up(xl) {
        content: '';
        height: 30px;
        width: 1px;
        background-color: rgba(167, 215, 255, 0.4);
        margin-left: 15px;
      }
    }
  }

  &__head {
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  &__add-project-container {
    position: relative;
  }

  &__add-project {
    position: relative;
    z-index: 2;
  }

  &__edit-institution {
    position: absolute;
    z-index: 100;
  }

  &__logo {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 170px;
    &-wrap {
      width: 40px;
      height: 40px;
      background-color: rgba($gray-base, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      font-size: $text-12;
      font-weight: 700;
      color: rgba($white, 0.5);
      text-decoration: none;
      @include media-breakpoint-down(xl) {
        width: 38px;
        height: 30px;
      }
      &:hover {
        color: $white;
      }
    }
    h1 {
      margin-left: 21px;
      width: min-content;
    }
    i {
      @include media-breakpoint-down(xl) {
        font-size: $text-16;
        margin-right: 10px;
      }
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  &__search {
    position: relative;
    width: 100%;
    @include media-breakpoint-down(xl) {
      text-align: right;
      margin-right: 5px;
    }
  }
  &__action {
    font-size: $text-16;
    border: 1px solid $blue_light;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 2px;
    color: $white;
    text-decoration: none;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    font-weight: 500;
    background: $primary;

    @include media-breakpoint-down(xl) {
      margin-right: 0;
    }

    &--filter {
      @include media-breakpoint-down(xl) {
        margin-right: 5px;
      }
    }
    span {
      position: relative;
      top: 0.5px;
    }
    &:disabled {
      opacity: 0.2;
      background-color: transparent;
      color: $white;
      border: 1px solid rgba(239, 242, 244, 0.3);
    }
    &:last-of-type {
      @include media-breakpoint-up(xl) {
        margin-right: 0;
      }
    }
    &:hover {
      background-color: rgba(239, 242, 244, 0.3);
      color: $white;
    }
    &--active {
      background-color: rgba(239, 242, 244, 0.3);
      @include media-breakpoint-down(xl) {
        display: none;
        margin-right: 0;
      }
    }
    &--small {
      font-size: $text-10;
    }
    &--medium {
      font-size: $text-14;
    }
    &--large {
      font-size: $text-16;
    }
    &--more {
      font-size: $text-20;
      @include media-breakpoint-down(lg) {
        border: none;
        width: auto;
        min-width: 11px;
        color: rgba(167, 215, 255, 0.8);
        font-size: $text-24;
        &:hover {
          background-color: transparent;
          color: $white;
        }
      }
    }
    &--alert {
      background-color: $secondary;
      border-color: $secondary;
      &:hover {
        background-color: $secondary-dark;
        border-color: $secondary-dark;
      }
    }
    &--add {
      width: 41px;
      transition: none;

      @include media-breakpoint-down(xl) {
        margin-right: 5px;
      }
      &.active {
        background-color: $blue;
        color: $white;
        position: absolute;
        top: -15px;
        border-color: $blue;
        height: 35px;
        border-radius: 2px 2px 0 0;
        i {
          margin-top: -4px;
        }
      }
    }
    i {
      &:before {
        vertical-align: middle;
      }
    }
  }
  &__action-wrap {
    position: relative;
    z-index: 1;
  }
  &__user {
    border: 1px solid rgba(239, 242, 244, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 4px 20px 5px 6px;
    border-radius: 2px;
    text-decoration: none;
    background: transparent;
    text-align: left;
    display: block;
    min-width: 115px;
    @include media-breakpoint-down(xl) {
      min-width: auto;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
    }
    &:hover {
      h2,
      h3 {
        color: $gray-medium;
      }
    }
    h2 {
      @include media-breakpoint-down(xl) {
        font-size: $text-16;
        line-height: 1;
        margin-top: 2px;
      }
    }
    &-wrap {
      position: relative;
      z-index: 3;
      min-width: 185px;
      margin-left: 7px;
      @include media-breakpoint-down(xl) {
        margin-left: 0;
        min-width: auto;
      }
      @include media-breakpoint-down(md) {
        z-index: 5;
      }
      button {
        min-width: 185px;
        @include media-breakpoint-down(xl) {
          min-width: 30px;
        }
      }
      .dropdown__content {
        width: 100%;
        @include media-breakpoint-down(xl) {
          width: max-content;
        }
      }
    }
  }
  &__dropdown {
    @include media-breakpoint-up(lg) {
      padding: 2px 0 1px;
      margin-right: 0;
    }
    @extend %reset-list;
    background-color: $white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 0 2px;
    padding-top: 10px;
    max-width: 185px;
    margin-right: 10px;

    li {
      @include media-breakpoint-up(lg) {
        padding: 2px 1px;
      }
      border-bottom: 1px solid rgba(167, 215, 255, 0.4);
      margin: 0 8px;
      padding: 3px 0;
      height: 100%;
      padding-bottom: 12px;
      margin-bottom: 10px;

      &:last-of-type {
        border: 0;
        margin: 0;
      }
    }
    .logout {
      color: $gray-dark;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 6px 10px;
      background: none;
      border: 0;
      text-align-last: left;
      width: 100%;
      justify-content: center;
      border-radius: 2px;

      @include media-breakpoint-up(lg) {
        padding: 3px 10px;
      }

      span {
        margin-right: 5px;
      }

      &:hover {
        background-color: rgba($primary-light, 0.5);
      }
      i {
        margin-top: 2px;
        margin-left: 6px;
      }
    }
    .header {
      &__inner {
        justify-content: flex-end;
      }
      &__action {
        background-color: transparent;
        color: $primary;
        border-color: $primary;
        padding: 0 1px 0 0;

        &:hover {
          background-color: $primary;
          color: $white;
        }
        &--active {
          display: block;
          background-color: $primary;
          color: $white;
        }
        &--large {
          padding-top: 3px;
        }
      }
    }
  }
  .button {
    @include media-breakpoint-down(xl) {
      width: 30px;
      min-width: auto;
      margin-left: 6px;
      span {
        margin: 0;
      }
    }
  }

  &__new-project {
    p {
      margin-top: 14px;
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
  }
}
