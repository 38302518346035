@import 'sass/exports/abstracts';

.not-found {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-around;
  margin-top: -221px;

  &__content {
    display: flex;
    flex-direction: column;

    img {
      padding-left: 30px;
      padding-right: 0;
      width: 220px;
      height: 85px;
    }
  }

  &-content {
    &__description {
      text-align: center;
      margin-top: 8px;
      font-weight: 700;
      font-size: $text-16;
      color: $gray-text;
    }

    &__link {
      text-align: center;
      font-size: $text-12;
      color: $gray-dark;

      a {
        text-decoration: underline;
        padding-left: 3px;
        color: $gray-text;
      }
    }
  }
}
