@import 'sass/exports/abstracts';

.calendar-day {
  &__zero-state {
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background-color: $white;
    padding: 30px;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 19px;
    width: 320px;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      margin: 110px 0 0 15px;
      max-width: 170px;
      padding: 15px 5px;
      height: 90px;
    }

    svg {
      width: 55px;
      height: 46px;
      @include media-breakpoint-down(md) {
        margin-top: 10px;
      }
    }

    p {
      margin-bottom: 0px;
      font-size: $text-16;
      font-weight: 400;
      color: $gray-text;
      margin-left: 16px;
      @include media-breakpoint-down(md) {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  &__zero-state-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border-color: transparent;
    color: $gray-dark;
  }
}
