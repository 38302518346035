@import 'sass/exports/abstracts';

.truncate-note {
  font-size: $text-10;
  background-color: $gray-base;
  color: $gray-dark;
  border: 1px solid $gray-medium;
  border-radius: 2px;
  padding: 0 2px;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  .table__truncate & {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
