@import 'sass/exports/abstracts';

.search-input {
  width: 100%;
  position: relative;
  @include media-breakpoint-down(xl) {
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
  }
  input {
    height: 30px;
    display: block;
    padding: 7px 30px 7px 10px;
    line-height: 1;
    border-radius: 2px;
    background-color: $gray-base;
    font-size: $text-14;
    border: none;
    width: 100%;

    @include media-breakpoint-down(xl) {
      max-width: 30px;
      background-color: transparent;
      &:focus {
        max-width: 280px;
        padding: 7px 30px 7px 10px;
        background-color: $white;
        & + label {
          border-color: transparent;
          i {
            color: $gray-dark;
          }
        }
      }
    }
    &:focus {
      outline: none;
    }
  }

  label {
    display: block;
    @include media-breakpoint-down(xl) {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border: 1px solid rgba(239, 242, 244, 0.3);
      border-radius: 2px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      pointer-events: none;
      cursor: pointer;
    }
  }

  i {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $gray-dark;
    @media (max-width: 1199px) {
      color: $white;
      pointer-events: none;
      top: 9px;
      right: 7px;
    }
    &:hover {
      cursor: pointer;
      color: $gray-text;
    }
  }
}
