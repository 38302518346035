@import 'sass/exports/abstracts';

.routes-dropdown {
  &__content {
    z-index: 1;
  }

  &__toggler {
    background-color: $secondary-dark;
    outline: none;
    border: transparent;
    padding: 0;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    display: inline;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    &:hover {
      background-color: $secondary;
    }
  }

  &__icon {
    i {
      color: $white;
      font-size: $text-16;
      &:before {
        vertical-align: middle;
      }
    }
  }

  &__nav {
    @extend %reset-list;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    min-width: 175px;
    margin: 0;

    @include media-breakpoint-down(lg) {
      margin-right: 0px;
    }

    li {
      margin-bottom: 3px;
    }

    a {
      font-weight: 500;
      padding: 6px 6px 6px 9px;
      border: 1px solid transparent;
      background-color: $white;
      border-radius: 2px;
      color: $primary;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-size: $text-14;
      text-transform: none;

      &.current {
        color: $primary;
        border: 1px solid rgba($primary, 0.5);
      }

      &:hover {
        color: $primary;
        background-color: rgba($primary-light, 0.5);
        border: 1px solid rgba($primary, 0.5);
      }

      i {
        font-size: $text-16;
        width: 20px;
        margin-right: 6px;
      }
    }
  }

  &__user-routes {
    padding: 2px;
    padding-bottom: 0px;
  }

  &__admin-routes {
    background-color: rgba($gray-medium, 0.3);
    padding: 2px;

    a {
      background-color: transparent;
    }
  }
}
