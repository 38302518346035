@import 'sass/exports/abstracts';

.auth-layout {
  background: url('/images/auth-bg.png') top/cover no-repeat fixed;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__translation {
    display: flex;
    position: absolute;
    top: 40px;
    right: 90px;
    .translation-button {
      font-family: $font-family;
      background-color: transparent;
      border: none;
      color: $primary;
      font-weight: 500;
      font-size: $text-12;

      &--active {
        color: $secondary;
      }
    }
  }

  h1 {
    font-size: $text-48;
    text-align: center;
    color: $primary;
    text-transform: none;
    margin-top: 50px;
    @include media-breakpoint-down(md) {
      font-size: $text-24;
      margin-top: 25px;
    }
  }

  h6 {
    font-size: $text-16;
    text-align: center;
    color: $gray-dark;
    font-weight: 400;
    margin-top: 30px;
  }
  &__wrap {
    max-width: 800px;
    margin: 50px auto;
    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }
  &__inner {
    background: rgba(255, 255, 255, 0.9);
    padding: 27px 24px 40px;
    @include media-breakpoint-down(md) {
      padding: 7px;
      margin: 0 15px;
    }
  }
  &__link {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      font-size: $text-12;
    }
    a {
      color: $primary;
      &:hover {
        color: $primary-dark;
      }
    }
  }
  &__footer {
    text-align: center;
    font-size: $text-10;
    font-weight: 300;
    color: $white;
    margin-top: 25px;
  }
  .form {
    &--login {
      max-width: 410px;
      margin: 0 auto;
      padding: 62px 0 53px;
      @include media-breakpoint-down(md) {
        max-width: none;
        padding: 20px 25px 30px;
      }
    }
    &__check {
      label {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        color: $gray-text;
        margin-bottom: 11px;
        line-height: 1;
        cursor: pointer;
        @include media-breakpoint-down(md) {
          font-size: $text-12;
        }
        &:before {
          margin-right: 13px;
        }
      }
      a {
        padding: 0 3px;
        color: $primary;
        &:hover {
          color: $primary-dark;
        }
      }
    }
  }
}
