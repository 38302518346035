@import 'sass/exports/abstracts';
.span__default {
  text-align: left;
  @include media-breakpoint-down(md) {
    font-size: $text-9;
  }
}

.calendar {
  &__nav {
    padding: 3px 10px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }

    .calendar-nav__dropdown {
      position: relative;
      right: 1px;
    }

    .header__action {
      @include media-breakpoint-down(lg) {
        &:first-of-type {
          margin-right: 5px;
        }
      }
    }
    h2 {
      text-transform: uppercase;
      color: $gray-medium;
      line-height: 1;
    }
    &-item {
      background-color: transparent;
      color: $gray-medium;
      border: none;
      font-size: 30px;
      padding: 1px 3px;
      margin-right: 10px;
      line-height: 1;
      i {
        vertical-align: middle;
      }
      &:hover {
        color: $primary-light;
      }
    }
  }
  &__week {
    background-color: rgba(3, 121, 191, 0.2);
    text-align: center;
    color: $gray-dark;
    text-transform: uppercase;
    font-size: $text-10;
    padding: 5px 12px;
    line-height: 1;

    @include media-breakpoint-down(lg) {
      padding: 5px 0px;
    }
  }
  &__days {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 122px); // 100vh - calendar__week - calendar__nav - navbar
    background-color: $white;
    padding: 12px 12px 0;
    @include media-breakpoint-down(lg) {
      padding: 0px;
    }
  }
  &__row {
    height: 20%;
    @include media-breakpoint-up(lg) {
      padding-bottom: 12px;
    }
    &:last-of-type {
      .calendar__day {
        border-bottom: none;
      }
    }
    .col {
      height: 100%;
      width: 14.52857143%;
      max-width: 14.52857143%;
      @include media-breakpoint-up(lg) {
        margin-bottom: 7px;
      }
      &:first-of-type {
        .calendar__day {
          @include media-breakpoint-down(lg) {
            border-left: 1px solid rgb(199, 223, 239);
          }
        }
      }
    }
  }
  &__day {
    background-color: $gray-light;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
    border-radius: 2px;
    height: 100%;
    border: 1px solid transparent;
    @include media-breakpoint-down(lg) {
      border-radius: 0;
      border-right: 1px solid rgb(199, 223, 239);
      border-bottom: 1px solid rgb(199, 223, 239);
    }

    &--wrapper {
      height: 100%;
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        border: 1px solid $gray-medium;
      }
      .calendar__date {
        background: rgba(167, 215, 255, 0.5);
        color: $gray-text;
        border-color: $primary;
      }
    }
    &--outside-month {
      box-shadow: none;
      pointer-events: none;
      .calendar__date {
        border-color: transparent;
        color: $gray-medium;
      }
    }
  }
  &__date {
    width: 21px;
    height: 18px;
    line-height: 1;
    border: 1px solid $primary;
    border-radius: 2px;
    text-align: center;
    margin: 0 auto 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--curent {
      background-color: $primary;
      color: $white;
    }
  }
  &__events-wrapper {
    height: calc(100% - 53px);
    overflow: hidden;
  }
  &__events {
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    margin-bottom: 0;
    li {
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__event {
    padding: 4px 6px;
    display: flex;
    align-items: baseline;
    border: none;
    background-color: transparent;
    width: 100%;
    @include media-breakpoint-down(lg) {
      padding: 4px 3px;
    }
    &:hover {
      background-color: $white;
    }
    i {
      margin-right: 4px;
      color: $gray-medium;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    span {
      @extend .span__default;
      color: $gray-text;
    }
    span.outside-month {
      @extend .span__default;
      color: $gray-medium;
    }
    &--starred {
      i {
        font-weight: 700;
        color: $yellow;
      }
    }
  }
  &__more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 25px 10px;
    background: $gray-light;
    @include media-breakpoint-down(lg) {
      padding: 5px 5px 10px;
    }
  }
  &__overflow {
    @include media-breakpoint-up(md) {
      padding: 0.3rem 0rem 0.3rem 1.8rem;
    }
    padding: 0.3rem 0rem 0.3rem 0.3rem;
  }
}

.calendar-dropdown {
  background-color: $white;
  border-radius: 2px;
  width: 222px;
  min-height: 226px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    font-size: $text-12;
    line-height: 1;
    i {
      font-size: $text-14;
    }
  }
  &__content {
    padding: 12px 8px;

    h5 {
      font-size: $text-10;
    }
    p {
      margin-bottom: 13px;
      margin-bottom: 13px;
      white-space: break-spaces;
      max-width: 270px;
    }
    .btn {
      width: 100%;
      margin-top: 10px;
    }
    .tag {
      margin-bottom: 3px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &__tags {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tag {
    margin-right: 4px;
  }

  &__members {
    p {
      font-size: $text-12;
      padding-right: 5px;
    }
  }
}

.event-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
  &__date {
    display: flex;
    align-items: baseline;
    i {
      margin-right: 4px;
    }
  }
  &__comment {
    display: flex;
    align-items: center;
    margin: 0 10px 0 8px;
    i {
      margin-right: 3px;
    }
  }
  &__notification {
    i {
      color: $gray-medium;
    }
    &--active {
      i {
        font-weight: 700;
        color: $secondary;
      }
    }
  }
  &__star {
    i {
      color: $gray-medium;
    }
    &--starred {
      i {
        font-weight: 700;
        color: $yellow;
      }
    }
  }
}

.dropdown {
  width: 100%;

  &__toggler {
    width: 100%;
  }
}
