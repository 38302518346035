@import 'sass/exports/abstracts';

.single-user {
  display: flex;
  background-color: $gray-light;
  position: relative;
  padding: 40px;
  min-width: 597px;

  @include media-breakpoint-down(sm) {
    padding: 30px;
    flex-direction: column;
  }
  @include media-breakpoint-down(md) {
    min-width: auto;
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: left;
    h2 {
      font-size: $text-21;
      font-weight: 700;
      color: $gray-dark;
      text-align: left;
      margin-bottom: 16px;
    }
    p {
      font-style: italic;
      color: $primary;
      font-weight: 700;
      font-size: $text-12;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
      i {
        font-size: $text-16;
        color: $primary;
        padding-right: 8px;
      }
    }
    i {
      font-size: 36px;
      color: rgb(175, 211, 233);
    }
  }

  .form__group {
    margin-bottom: 10px;
    input {
      border-radius: 4px;
      padding: 6px 10px;
    }

    input[type='radio'] {
      width: auto;
    }
    input[type='radio'] {
      width: auto;
    }

    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        color: $gray-medium;
        &:hover {
          cursor: pointer;
        }
        &:before {
          content: '';
          background: $white;
          border-radius: 100%;
          border: 1px solid $gray-medium;
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: -1px;
          margin-right: 10px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          color: $gray-text;
          &:before {
            background-color: $primary;
            box-shadow: inset 0 0 0 2px $white;
            border: 1px solid $primary;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $primary;
          }
        }
      }
    }
  }

  &__form-wrapper {
    width: 380px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__check {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;

    &--disabled {
      opacity: 0.3;
    }

    input {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  &__checks {
    display: flex;
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      margin-top: 15px;
    }
  }

  &__actions {
    margin-top: 71px;
    margin-left: 20px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .sidebar__dropdown {
    box-shadow: none;
    background-color: transparent;
    text-transform: capitalize;
  }

  &__action {
    margin-bottom: 5px;
    text-transform: capitalize;

    button {
      text-transform: capitalize;
      background-color: $gray-base;
      outline: none;
      min-width: 115px;
      border-color: transparent;
      color: $gray-dark;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 2px;
      transition: all 0.2s ease;
      padding-left: 5px;
      font-size: $text-12;

      &:disabled {
        opacity: 0.3;
      }

      span {
        position: relative;
        font-size: $text-20;
        line-height: 1;
        left: -11px;
        top: -3px;
        font-weight: 300;
      }

      i {
        margin-right: 10px;
        font-size: $text-14;
        color: $gray-dark;
      }

      &:disabled {
        background-color: $gray-base;
        outline: none;
        border-color: transparent;
        color: $gray-dark;
      }

      &:hover {
        background: rgba(167, 215, 255, 0.5);
        color: $primary;
        i {
          color: $primary;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .btn-close {
    color: $gray-medium;
    font-size: $text-24;
    top: 10px;
  }
  .btn__wrap {
    margin-bottom: 0;
  }
}
