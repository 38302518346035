@import '/src/sass/exports/abstracts';

.navbar-edit-institution {
  &__name {
    h1 {
      margin-left: 16px;
      @include media-breakpoint-down(sm) {
        margin-left: 7px;
      }
    }

    &-tooltip {
      z-index: 13;
      position: absolute;
      top: 32px;
      width: 100%;
      background-color: $white;
      border: solid 1px $gray-dark;
      border-radius: 2px;
      color: $gray-dark;
      font-size: $text-10;
      padding: 6px 7px;
      font-weight: 400;
      text-transform: none;
      word-break: break-word;
    }
  }
}
