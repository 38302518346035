@import 'sass/exports/abstracts';

.mobile-documents-list {
  &__list {
    ul {
      @extend %reset-list;

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  &__item {
    font-size: $text-12;
    color: $gray-dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__close {
      width: 15px;
      height: 15px;
      min-width: 15px;
      max-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: $primary-light;
      background: none;
      border: none;
      background-color: $gray-base;
      color: $primary-light;
      margin-left: 10px;

      i {
        margin-top: 2px;
      }
    }
  }

  &__zero-state {
    margin: 20px 0px;
    background-color: $white;

    p {
      display: block;
      font-size: $text-12;
      font-weight: 400;
      color: $gray-medium;
      text-align: center;
      line-height: 15px;
    }
  }

  &__action {
    background-color: $gray-base;
    padding: 7px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: $text-14;
    border: none;
    color: $gray-dark;
  }
}
