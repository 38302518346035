@import 'sass/exports/abstracts';

.tag-overview {
  &__action {
    button {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      width: 100%;
      height: auto;
      padding: 8px 0px;
      border: 1px solid transparent;
      border-radius: 2px;
      transition: all 0.2s ease;
      font-size: $text-14;

      &:hover {
        background-color: $white;
        border: 1px solid $primary;
        color: $primary;
      }
    }
  }
}
