@import 'sass/exports/abstracts';

.navbar-search {
  &__list {
    background-color: $gray-light;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    max-height: 250px;
    padding: 0.5rem 0.25rem;
    border: 1px solid $gray-dark;
    border-radius: 3px;
    overflow: auto;

    button {
      border: 0;
      background: none;
      text-align: left;
      padding: 0.5rem;
      border-bottom: 1px solid $gray-dark;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
