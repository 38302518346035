.dashboard-tag-filter {
  span {
    display: block;
    text-transform: none;
  }
}

.popup__body {
  padding: 0px;
}

.popup {
  padding: 9px 7px 10px;
}
