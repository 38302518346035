@import 'sass/exports/abstracts';

.project {
  h2 {
    color: $gray-text;
    font-size: $text-21;
    font-weight: 700;
    margin-left: 10px;
  }
  h3 {
    font-size: $text-14;
    font-weight: 500;
    color: $gray-text;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      color: $gray-dark;
    }
  }
  p {
    display: flex;
    align-items: center;
    margin: 0;
    color: $gray-dark;

    span {
      margin: 0px 3px;

      &.tag {
        margin: 0 0 0 3px;
        color: $gray-text;
        &:first-of-type {
          margin-left: 8px;
        }
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
    margin: 0;

    @include media-breakpoint-down(lg) {
      font-size: $text-12;
    }

    span {
      margin-left: 5px;
      color: $gray-dark;
      &.tag {
        margin: 0 0 0 3px;
        color: $gray-text;
        &:first-of-type {
          margin-left: 8px;
        }
      }
    }

    &-remainder {
      padding: 5px 6px;
      color: $white;
      background-color: $bitter-sweet;
      font-size: $text-10;
      font-weight: 700;
      border-radius: 2px;

      i {
        font-size: $text-12;
        padding-right: 4px;
      }
    }
  }

  &__text-container {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  .dropdown {
    width: auto;

    &__toggler {
      width: auto;
    }

    &__content {
      z-index: 1;
    }
  }

  .popup {
    p {
      span {
        &.tag {
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__tags-overview {
    z-index: 100;
  }

  &__tags-list {
    height: 200px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      margin-top: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-medium;
      border-radius: 100vw;
    }
  }

  &__star {
    font-weight: 400;

    @include media-breakpoint-down(lg) {
      font-size: $text-18;
      margin-top: 2px;
    }

    &--starred {
      color: $yellow;
      font-weight: 900;
    }
  }

  &__item-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 750px;
    }
    @include media-breakpoint-up(xl) {
      width: 800px;
    }
  }
  &__main {
    padding: 5px 0 20px;
    @include media-breakpoint-down(md) {
      padding-bottom: 5px;
    }

    .project-activity {
      &__header {
        display: flex;
        i {
          font-size: $text-16;
          margin-bottom: 19px;
          margin-right: 10px;
        }
        h3 {
          margin-left: 15px;
          margin-bottom: 1px;
          font-size: $text-14;
          height: 14px;

          @include media-breakpoint-down(md) {
            margin-left: 0px;
          }
        }
      }

      &__content {
        ul {
          padding-left: 39px;
          margin-top: -2px;

          @include media-breakpoint-down(md) {
            padding-left: 0px;
            margin-top: 10px;
          }

          .activity-log {
            &__name {
              display: block;
              font-weight: 700;
              font-size: $text-14;
              padding-right: 5px;
              span {
                font-weight: 400;
                word-break: break-all;
                color: $gray-dark;
              }
            }
            &__date {
              padding: 3px 0 10px 0;
              text-transform: capitalize;
              color: $gray-medium;
              font-size: $text-12;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  &__content {
    width: 100%;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 17px 0 9px 18px;
    pointer-events: auto;
    max-height: 100%;

    @include media-breakpoint-up(md) {
      height: 100%;
    }
    @include media-breakpoint-down(md) {
      padding: 0 0 20px;
    }
  }
  .btn-close {
    right: 17px;
    top: 13px;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 0 35px 9px 0;
    @include media-breakpoint-down(md) {
      padding-top: 8px;
      position: relative;
      left: -5px;
    }

    .project-star {
      opacity: 1;

      @include media-breakpoint-up(md) {
        display: block;
      }
      @include media-breakpoint-between(xs, md) {
        display: none;
      }
    }

    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    input,
    textarea {
      border: none;
      padding: 0;
      width: 100%;
      height: 35px;
      font-size: $text-21;
      font-weight: 700;
      padding-left: 10px;
      border: 2px solid transparent;

      @include media-breakpoint-down(md) {
        padding-left: 10px;
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(md) {
        font-size: $text-16;
        padding-left: 5px;
      }
      &:focus,
      &:placeholder-shown {
        border: 2px solid $gray-dark;
        border-radius: 2px;
        outline: none;
      }
    }
    i {
      margin: 2px 8px 0 0;
      font-size: $text-16;
    }
  }

  &__header-title {
    resize: none;
    overflow-wrap: break-word;
    overflow: hidden;
    background-color: transparent;

    &::placeholder {
      line-height: 29px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 1px;
    }
  }

  &__description-text {
    p {
      overflow-wrap: break-word;
      display: block;
    }
  }

  &__inner {
    margin: 4px 0 20px 39px;
    @include media-breakpoint-down(md) {
      margin: 4px 0 10px 0;
    }
    p {
      line-height: 1.28;
    }
    label {
      margin-bottom: 4px;
      font-weight: 700;
      color: $gray-dark;

      strong {
        margin-right: 8px;
      }
      span {
        font-size: $text-12;
      }
    }

    .description-edit {
      border: solid 2px $gray-dark;
      border-radius: 2px;
      padding: 13px;
      width: 100%;
      color: $gray-dark;
      line-height: 1.29;
      resize: none;
      &:focus {
        outline: none;
      }
    }
  }

  &__comments {
    margin-top: 15px;
  }

  &__comment-date {
    font-weight: 400;
    font-size: $text-12;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    padding-bottom: 10px;

    &--main {
      i {
        font-size: $text-16;
        margin-right: 24px;
      }
    }
  }
  &__title-more {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: $gray-base;
    color: $primary-light;
    margin-left: 5px;
    border: 0;
    transition: all 0.1s ease;

    @include media-breakpoint-down(lg) {
      margin-right: 10px;
    }

    &:hover {
      background: rgba($primary-light, 0.5);
      i {
        color: $gray-dark;
      }
    }
    i {
      font-weight: 400;
      margin: 2px 0 0;
      font-size: $text-14;
      color: $primary-light;
    }

    &--open {
      background: rgba($primary-light, 0.5);
      i {
        color: $gray-dark;
      }
    }
    &--document {
      height: 30px;
      width: auto;
      color: $gray-dark;
      i {
        margin-right: 5px;
      }
    }
  }

  &__document {
    &__zero-state {
      @include media-breakpoint-down(md) {
        display: none;
      }

      p {
        display: block;
        font-weight: 400;
        color: $gray-medium;
        font-size: $text-12;
        line-height: 15px;
      }
    }

    &--toggler {
      display: flex;
      margin-right: 30px;
      align-items: center;
      background: none;
      border: 0;
      padding: 7px 8px;
      color: $gray-dark;
      border-radius: 2px;
      transition: all 0.2s ease;

      @include media-breakpoint-down(lg) {
        margin-right: 0px;
      }

      &:focus {
        background-color: $diamond;
      }

      i {
        margin: 2px 7px 0 0;
        font-size: $text-16;
        min-width: 20px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      span {
        margin-left: 2px;
      }
    }
  }

  &__textarea {
    padding-bottom: 5px;
    textarea {
      border: none;
      background-color: transparent;
      width: 100%;
      height: 82px;
      resize: none;
      padding: 0;
      color: $gray-dark;
      line-height: 1.28;
      &:placeholder-shown,
      &:focus {
        padding: 9px 14px;
        background: $gray-light;
        border: 1px solid $gray-base;
        border-radius: 2px;
        outline: none;
      }
    }
    &--comment {
      margin-bottom: 20px;
      textarea {
        padding: 9px 14px;
        background: transparent;
        border: transparent;
        outline: none;
        height: auto;
        &:placeholder-shown,
        &:focus {
          background: transparent;
          border: transparent;
        }
      }
    }
  }
  &__comment {
    background: $gray-light;
    border: 1px solid $gray-base;
    border-radius: 2px;
    #comment {
      min-height: 36px;
    }
    &__action {
      padding: 0 14px 9px;
    }
    &__inner {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__controls {
      display: flex;

      &__control {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin-left: 8px;
        text-align: center;
        padding: 1px 1px 1px 2px;
        color: $gray-dark;

        &:hover {
          background-color: $gray-base;
        }
        i {
          display: block;
          margin-top: 3px;
        }
      }
    }

    &--text {
      word-break: break-all;
      padding: 8px 13px 21px;

      p {
        margin-bottom: 9px;
        display: block;
        a {
          margin: auto 5px;
        }
      }
    }
  }

  &__reactions-container {
    display: flex;
    span {
      margin-right: 11px;
    }
  }

  &__reactions {
    max-width: 400px;
    height: 100%;
    flex-wrap: wrap;
    margin-top: 7px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    a,
    span {
      margin-right: 11px;
    }
    span {
      cursor: pointer;
    }

    &__response {
      background-color: transparent;
      border: none;

      &:disabled {
        background-color: unset;
      }
    }

    &__reaction {
      display: flex;
      align-items: center;
      padding: 1px 4px;
      border: 1px solid $gray-medium;
      border-radius: 2px;
      margin: 0 10px 5px 0;

      span {
        margin-left: 3px;
        margin-right: 2px;
        color: $gray-dark;
        font-weight: 700;
      }

      &--active {
        background-color: $gray-base;
      }
    }
    &__add {
      &:hover {
        background-color: $gray-base;
        border-radius: 2px;
      }
    }
    &__response {
      color: $gray-dark;
      font-size: $text-12;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $gray-dark;
      }
    }
  }
  &__sidebar {
    padding: 13px 18px;
    background-color: $gray-light;
    border-radius: 2px;
    height: 100%;
    margin-right: -2px;

    &--mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        padding: 15px 7px;
        border-bottom: 1px solid rgba(175, 196, 212, 0.3);
        background: $gray-light;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        margin: 0 1px;
        margin-bottom: 18px;

        .project__title {
          padding-bottom: 0;
          &-more {
            &:hover {
              i {
                color: $gray-dark;
              }
            }
          }
        }
      }
    }
    &--new {
      height: calc(100% - 50px);
      margin-bottom: 20px;
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 100;
    top: 40px;
    right: 50px;
    font-size: $text-12;
    font-weight: 400;
    color: $gray-dark;
    background-color: $white;
    border-radius: 2px;
    border: 1px solid $gray-dark;
    padding: 6px;
    width: 180px;
    text-align: left;
    line-height: 130%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  &__actions {
    @extend %reset-list;
    margin: 6px 0 17px;
    position: relative;
    @include media-breakpoint-down(md) {
      display: flex;
      margin: 0;
    }

    li {
      margin-bottom: 5px;
      @include media-breakpoint-down(md) {
        margin: 0 0 0 10px;
      }
    }
    button {
      display: flex;
      align-items: center;
      background: none;
      border: 0;
      width: 100%;
      padding: 7px 8px;
      background-color: $gray-base;
      color: $gray-dark;
      border-radius: 2px;
      transition: all 0.1s ease;

      &:hover {
        background-color: rgba(167, 215, 255, 0.5);
      }
      i {
        margin: 2px 7px 0 0;
        font-size: $text-16;
        min-width: 20px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      &:disabled {
        opacity: 0.3;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }
  &__documents {
    height: 220px;
    overflow-y: scroll;
    padding-right: 5px;

    @include media-breakpoint-down(lg) {
      overflow: hidden;
      padding-right: 0px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-medium;
    }

    ul {
      padding-left: 6px;
      @extend %reset-list;

      @include media-breakpoint-down(md) {
        display: none;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
      }
    }
  }

  &__close-modal {
    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
  }

  &__document {
    font-size: $text-12;
    color: $gray-dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
      color: $gray-text;
      cursor: pointer;
    }
    &__close {
      width: 20px;
      height: 20px;
      min-width: 20px;
      max-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: $primary-light;
      background: none;
      border: none;
      &:hover {
        background-color: $gray-base;
        color: $primary-light;
      }
      i {
        margin-top: 2px;
      }
    }
    &--text {
      &:hover {
        cursor: unset;
        text-decoration: none;
        color: $gray-dark;
      }
    }
  }

  &-archived {
    margin-bottom: 5px;

    &__title {
      margin-bottom: 5px;
      font-weight: 500;
      color: $secondary-dark;
    }

    &__action {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 2px;
      background-color: $secondary-dark;
      color: $white;
      transition: all 0.2s ease;

      p {
        color: $white;
        display: block;
      }

      i {
        margin-right: 9px;

        @include media-breakpoint-down(lg) {
          justify-self: flex-end;
        }

        @include media-breakpoint-down(md) {
          margin-right: 0px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken($secondary-dark, 7%);
      }
    }
  }
}
