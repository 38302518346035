@import 'sass/exports/abstracts';

.notification-popup {
  z-index: 1;
  position: relative;
  left: 1px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 0 5px 5px 5px;
  margin-top: 10px;
  max-width: 305px;
  max-height: calc(60vh + 30px); // 60% of view height + 'read more' button height

  @include media-breakpoint-up(xs) {
    width: 100vw;
  }

  @include media-breakpoint-up(xs) {
    max-height: calc(80vh + 40px); // 80% of view height + 'read more' button height
  }

  &__header {
    margin: 0;
    height: 40px;
    border-radius: 2px 2px 0 0;

    button {
      position: relative;
      bottom: 39px;
      left: 133px;
      border: none;
      color: $gray-dark;
      background-color: transparent;
      font-size: $text-16;
      &:hover {
        color: $primary;
      }
    }

    h4 {
      text-align: center;
      padding: 13px 0;
    }
  }

  &-body {
    border-radius: 0;
    color: $gray-text;
    font-size: 12px;
    line-height: 14px;
    width: 100%;

    &__filter {
      position: absolute;
      left: 7px;
    }
    &__mark-all {
      position: absolute;
      right: 7px;
    }
    &__read {
      background-color: transparent;
      border: transparent;
      text-decoration: underline;
      text-align: center;
      width: 100%;
      padding: 4px 0;
    }

    &__btn {
      display: flex;
      height: 15px;
      justify-content: space-between;
      margin: 10px 0;
      p {
        text-decoration: underline;
      }
    }
    &__list {
      min-height: 100px;
      background: $gray-light;
      padding-bottom: 5px;
      position: relative;
      left: -5px;

      &__zero-state {
        height: 28vh;
        background-color: $white;
        text-align: center;
        width: 100%;

        .no-notification-icon {
          font-family: 'Font Awesome 5 Pro';
          font-size: $text-63;
          font-weight: 400;
          color: $link-water;
          width: 100%;
          padding: 40px 0 20px 0;
        }
        h2 {
          font-weight: 700;
          font-size: $text-16;
          color: $gray-text;
        }
        p {
          font-size: $text-12;
          color: $gray-dark;
          padding: 10px 60px 0 60px;
          span {
            text-decoration: underline;
            color: $gray-text;
          }
        }
      }

      &--less {
        height: 100%;

        .zero-state {
          .no-notification-icon {
            font-family: 'Font Awesome 5 Pro';
            font-size: $text-63;
            font-weight: 400;
            color: $link-water;
            width: 100%;
            padding: 40px 0 20px 0;
            text-align: center;
          }
          h2 {
            font-weight: 700;
            font-size: $text-16;
            text-align: center;
            color: $gray-text;
          }
          p {
            font-size: $text-12;
            color: $gray-dark;
            text-align: center;
            padding: 10px 60px 0 60px;
            span {
              text-decoration: underline;
              text-align: center;
              color: $gray-text;
            }
          }
        }
      }
      &--more {
        height: 50vh;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 100%;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-medium;
          border-radius: 100vw;
        }

        @include media-breakpoint-down(lg) {
          height: 70vh;
          margin-bottom: 10px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 5px 0 0 0;
      margin-bottom: -10px;
      height: 100%;
      li {
        display: flex;

        .notification-item__status {
          width: 20px;
          position: relative;
          font-size: $text-14;
          color: $gray-medium;
          margin: 2px -25px 0 7px;

          span {
            position: absolute;
            z-index: 3;
            height: 35px;
            background: $white;
            border-radius: 3px;
            border: 1px solid $gray-dark;
            font-size: $text-10;
            padding: 4px;
            top: 20px;
            right: -58px;
          }
        }
      }
    }
    &__item {
      background: $white;
      border: 1px solid $gray-medium;
      border-radius: 2px;
      width: 260px;
      height: 100%;
      color: black;
      position: relative;
      left: 30px;
      margin-bottom: 10px;

      &:hover {
        border: 1px solid $primary;
      }

      .starred {
        color: $yellow;
        font-weight: 900;
      }

      .header {
        background-color: rgba(3, 121, 191, 0.2);
        border-radius: 2px 2px 0px 0px;
        display: flex;
        padding: 4px 5px 4px 0;
        margin-bottom: 10px;
        word-break: break-word;

        span {
          font-family: 'Font Awesome 5 Pro';
          line-height: 14px;
          color: $gray-medium;
          padding: 5px 9px 0 8px;

          i {
            font-size: $text-14;
          }
        }
        h2 {
          font-weight: 700;
          font-size: $text-16;
          line-height: 19px;
          color: $gray-text;
        }
      }

      .notification-item {
        &-section {
          color: $gray-dark;
          display: flex;
          align-items: center;
          height: 100%;
          margin-bottom: 10px;

          p {
            margin-bottom: 0px;
          }

          &__author {
            margin: -4px 0 10px 8px;
            font-size: $text-12;
            font-weight: 700;
          }
          &__date {
            margin: -3px 0 10px 8px;
            font-weight: 400;
            font-size: $text-10;
          }

          &__body-icon {
            margin: -2px 13px 0 8px;
            i {
              font-size: $text-14;
            }
            p {
              font-size: $text-12;
            }
          }

          &__description {
            background: $gray-light;
            border: 1px solid $gray-base;
            border-radius: 2px;
            padding: 5px;
            font-size: $text-12;
            margin-right: 15px;
            max-width: 225px;
            word-break: break-all;
            margin-bottom: 0px;

            span {
              color: $primary;
              font-weight: 700;
            }

            p {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
