@import 'sass/exports/abstracts';

.sidebar {
  min-width: 164px;
  background-color: $gray-light;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &__nav {
    @extend %reset-list;
    padding: 15px 12px;
    li {
      margin-bottom: 15px;
    }
    a {
      font-weight: 500;
      padding: 6px 5px 6px 9px;
      border: 1px solid $gray-base;
      background-color: $white;
      border-radius: 2px;
      color: $primary;
      text-decoration: none;
      display: flex;
      align-items: center;
      &.current,
      &:hover {
        color: $primary;
        background-color: rgba($primary-light, 0.5);
        border: 1px solid rgba($primary, 0.5);
      }
      i {
        font-size: $text-16;
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  &__action {
    height: 40px;
    border-color: #81bcdf;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__action-wrap {
    position: relative;
  }
  &__dropdown {
    @extend %reset-list;
    background-color: $white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 6px 2px 2px;

    .user-sidebar__wrapper {
      width: 100%;
      font-size: $text-12;
      padding: 5px;
      &:hover {
        color: $primary;
      }
      i {
        margin-right: 8px;
      }
    }
    a {
      color: $gray-dark;
      font-size: $text-12;
      display: block;
      text-decoration: none;
      text-transform: none;
      padding: 4px 11px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      width: max-content;
      font-weight: 400;
      &:hover {
        color: $primary;
      }
      i {
        margin-right: 6px;
        font-size: $text-14;
        width: 16px;
      }
    }
  }
  .dropdown {
    position: relative;
    z-index: 1;
  }
}
