@import 'sass/exports/abstracts';

.table {
  width: 100%;
  table-layout: auto !important;
  border-collapse: collapse;

  thead {
    color: $gray-medium;
    text-transform: uppercase;
    font-size: $text-12;
    font-weight: 500;
    tr {
      background-color: $primary;
      border-right: none;
      &:hover {
        cursor: default;
      }
      th {
        margin-top: -1px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(175, 196, 212, 0.3);
      background-color: $gray-light;
      &:hover {
        background: $white;
        cursor: pointer;
      }
    }
  }

  &__responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__check-wrap {
    padding: 0 8px;
    background-color: rgba(239, 242, 244, 0.6);
    display: flex;
    align-items: center;
    height: calc(100% - 2px);
    margin: 1px 0;
    border-radius: 2px 0px 0px 2px;
  }

  &__check {
    background-color: rgba(239, 242, 244, 0.6);
    padding: 10px 12px;
  }

  &__truncate {
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
  }

  &__truncated {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
  }

  &__empty-row {
    width: 0;
    padding: 0;
  }

  &__tag-tooltip {
    position: absolute;
    top: 21px;
    left: -23px;
    background-color: $white;
    border: solid 1px $gray-dark;
    border-radius: 2px;
    color: $gray-dark;
    font-size: $text-10;
    padding: 6px 7px;
    font-weight: 400;
    text-transform: none;
  }
}

.grid {
  &__check {
    background-color: rgba(239, 242, 244, 0.6);
    padding: 10px 12px;

    @include media-breakpoint-down(sm) {
      padding: 10px 12px 10px 11px;
    }
  }
}
