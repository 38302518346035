@import 'sass/exports/abstracts';

.project-description {
  &__actions {
    display: flex;
    align-items: center;
    margin-top: 13px;
  }

  &__submit {
    outline: none;
    background: $primary;
    border-radius: 2px;
    border: transparent;
    color: $white;
    padding: 7px 10px;
    margin-right: 14px;
    font-weight: 500;
    transition: all 0.2s ease;

    span {
      display: block;
    }

    &:hover {
      background: darken($primary, 7%);
    }
  }

  &__close {
    outline: none;
    border: transparent;
    background-color: transparent;
    margin-top: 3px;

    i {
      font-size: 25px;
      color: $gray-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      i {
        color: darken($gray-dark, 10%);
      }
    }
  }
}
