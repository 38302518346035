@import 'sass/exports/abstracts';

.add-user {
  background-color: $gray-light;
  position: relative;
  padding: 40px;
  min-width: 597px;
  @include media-breakpoint-down(md) {
    padding: 40px 12px;
    min-width: auto;
  }

  &__inner {
    text-align: center;
    margin-bottom: 48px;
    h2 {
      font-size: $text-21;
      font-weight: 700;
      color: $gray-dark;
      text-align: left;
    }
    i {
      font-size: 36px;
      color: rgb(175, 211, 233);
    }
  }
  .form__group {
    margin-bottom: 10px;

    input {
      border-radius: 4px;
      padding: 6px 10px;

      &:focus {
        border-color: $primary;
      }

      @include media-breakpoint-up(md) {
        max-width: 380px;
      }
    }

    input[type='radio'] {
      width: auto;
    }

    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        color: $gray-medium;
        &:hover {
          cursor: pointer;
        }
        &:before {
          content: '';
          background: $white;
          border-radius: 100%;
          border: 1px solid $gray-medium;
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: -1px;
          margin-right: 10px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          color: $gray-text;
          &:before {
            background-color: $primary;
            box-shadow: inset 0 0 0 2px $white;
            border: 1px solid $primary;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $primary;
          }
        }
      }
    }
  }

  &__checks {
    display: flex;
    margin-top: 15px;
    @include media-breakpoint-down(lg) {
      margin-top: 15px;
    }
  }

  &__check {
    display: flex;
    align-items: center;
    margin-right: 40px;
    color: $gray-medium;
    input {
      margin-right: 5px;
    }
  }
  .btn--primary {
    &:disabled {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
  .btn--secondary {
    &:disabled {
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
  }
  .btn-close {
    color: $gray-medium;
    font-size: $text-24;
    top: 10px;
  }
}
