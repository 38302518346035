@import 'sass/exports/abstracts';

.checkbox {
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:disabled + label {
    opacity: 0.3;
  }

  input[type='checkbox'] + label:before {
    content: ' - ';
    border: 1px solid $gray-dark;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 2px;
    display: inline-block;
    color: transparent;
    cursor: pointer;
    text-align: center;
    background-color: $white;
    .check-main &,
    .archive-table__header &,
    .tag-table__checkbox &,
    .dashboard-table__checkbox &,
    .dashboard-table__tooltip &,
    .deleted-projects-table__header & {
      background-color: rgba(239, 242, 244, 0.5);
      border: 1px solid rgba(239, 242, 244, 0.5);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }
  }

  input[type='checkbox']:checked + label:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f00c';
    color: $primary;
    font-size: $text-16;
    line-height: 1;
    .check-main &,
    .archive-table__header &,
    .deleted-projects-table__header &,
    .dashboard-table__tooltip &,
    .tag-table__checkbox & {
      content: ' - ';
      font-size: $text-24;
      line-height: 0.5;
      font-weight: 700;
    }
  }
}
