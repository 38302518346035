@import 'sass/exports/abstracts';

.tag-list {
  z-index: 100;

  &__tags {
    height: 250px;

    @include media-breakpoint-up(md) {
      overflow-y: scroll;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      margin-top: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-medium;
      border-radius: 100vw;
    }
  }
}
