@import 'sass/exports/abstracts';

.add-organizer {
  width: 500px;
  min-height: 260px;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 50px 80px;
  position: relative;
  @include media-breakpoint-down(md) {
    margin: 120px auto 0;
  }
  @include media-breakpoint-down(sm) {
    width: 336px;
    min-height: auto;
    padding: 30px 20px;
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    background-color: transparent;
    outline: none;
    border: transparent;
    font-size: $text-16;
    color: $gray-dark;
  }

  &__header {
    display: flex;
    h3 {
      color: $gray-text;
      font-size: $text-16;
      line-height: 19px;
      font-weight: 400;
    }

    i {
      color: $secondary;
      font-size: 2.25rem;
      margin-right: 15px;
      align-self: flex-start;
      margin-top: 3px;
    }
  }

  &__organizer {
    margin-top: 30px;
  }

  &__select {
    border: 1px solid $gray-medium;
    padding: 8px;
    border-radius: 2px;
    cursor: pointer;

    input {
      border: transparent;
      outline: none;
      width: 93%;
    }
  }

  &__list {
    position: absolute;
    width: 340px;
    max-height: 206px;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-top: 2px;
    padding: 10px;
    z-index: 100;

    @include media-breakpoint-only(xs) {
      width: 295px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      margin: 5px 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-medium;
      border-radius: 100vw;
    }

    ul {
      list-style: none;
      padding-left: 0px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &__list-item {
    margin-bottom: 5px;
    padding: 3px 7px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: $gray-light;
      cursor: pointer;

      i {
        visibility: visible;
      }
    }

    h4 {
      font-size: $text-14;
      color: $gray-dark;
      text-transform: none;
      font-weight: 400;
      margin-bottom: 0px;
    }

    span {
      font-size: $text-12;
      color: $gray-medium;
    }

    i {
      color: $primary-light;
      font-size: $text-14;
      visibility: hidden;
    }

    &--selected {
      i {
        visibility: visible;
      }
    }
  }

  &__actions {
    margin-top: 20px;

    .btn {
      margin-right: 10px;
      text-transform: uppercase;
    }

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
