@import 'sass/exports/abstracts';

.notification {
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 1rem;
  z-index: 999;

  button {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 15px;
    right: 14px;

    i {
      color: $gray-text;
      $font-size: $text-16;
    }
  }

  &--error {
    background: $secondary-dark;
    color: white;
    font-size: $text-16;
  }
  &--success {
    background: $green;
    color: $gray-text;
    font-size: $text-16;

    span {
      padding-right: 15px;
      font-size: $text-16;

      &::after {
        padding-left: 3px;
        padding-bottom: 2px;
        font-weight: 900;
        font-family: 'Font Awesome 5 Pro';
        font-size: $text-7;
        content: '\f00c';
        position: relative;
        top: -3px;
      }
    }
  }
}
